export const fridgeTypes = [
  "Walk-in Cooler",
  "Walk-in Freezer",
  "Reach-in Refrigerator",
  "Reach-in Freezer",
  "Under Counter Refrigerator",
  "Under Counter Freezer",
  "Display Case",
  "Ice Machine",
  "Blast Chiller",
  "Refrigerated Prep Table",
  "Beer Fridge",
  "Wine Cooler",
  "OTHER"
];

export const fridgeBrands = [
  "Foster",
  "Williams",
  "True",
  "Polar",
  "Gram",
  "Electrolux Professional",
  "Hobart",
  "Scotsman",
  "Hoshizaki",
  "Interlevin",
  "Precision",
  "Adande",
  "Fagor",
  "Infrico",
  "Atosa",
  "Tefcold",
  "Blizzard",
  "Mercatus",
  "Iarp",
  "OTHER"
];

export const pricing = {
  basePrice: 132,
  urgentFee: 0,
  serviceTypeFees: {
    repair: 0,
    maintenance: 0,
    installation: 0
  }
};