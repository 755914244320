import React from 'react';
import bannerImage from '../assets/banners/home-bg.jpg';

const Hero = () => {
  return (
    <section className="relative h-[40vh] sm:h-[50vh] flex items-center justify-center overflow-hidden">
      <div className="absolute inset-0">
        <div className="w-full h-full" style={{
          backgroundImage: `url(${bannerImage})`,
          backgroundPosition: 'center 35%',
          backgroundSize: '110%',
          backgroundRepeat: 'no-repeat',
          filter: 'blur(3px)',
        }}></div>
        <div className="absolute inset-0 bg-black opacity-60"></div>
      </div>
      <div className="relative z-10 container mx-auto px-4 text-center">
        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-extrabold text-white mb-3 sm:mb-4 leading-tight tracking-tight">
          Expert Commercial{''}
          <span className="inline sm:hidden">&nbsp;</span>
          <br className="hidden sm:block" />
          Refrigeration Repairs
        </h1>
        <p className="text-sm sm:text-base md:text-lg lg:text-xl text-gray-200 max-w-xs sm:max-w-lg md:max-w-2xl lg:max-w-3xl mx-auto leading-relaxed">
          Serving <span className="font-semibold">London, Hertfordshire, Essex, Kent, Surrey, and Sussex</span> with fast, reliable repairs for all types of commercial fridges and freezers.
        </p>
      </div>
    </section>
  );
};

export default Hero;
