import React from 'react';
import { InputWithIcon, SelectWithIcon } from './FormComponents';
import refrigerationTypes from '../config/refrigerationTypes';

const fridgeTypes = refrigerationTypes.map(type => ({ value: type, label: type }));

const fridgeBrands = [
  { value: 'Foster', label: 'Foster' },
  { value: 'Williams', label: 'Williams' },
  { value: 'Polar', label: 'Polar' },
  { value: 'True', label: 'True' },
  { value: 'Gram', label: 'Gram' },
  { value: 'Electrolux', label: 'Electrolux' },
  { value: 'Liebherr', label: 'Liebherr' },
  { value: 'Other', label: 'Other' },
];

const RepairForm = ({ formData, handleInputChange, errors }) => {
  return (
    <>
      <SelectWithIcon
        icon="fa-snowflake"
        name="fridgeType"
        value={formData.fridgeType}
        onChange={handleInputChange}
        options={fridgeTypes}
        required
        label="Fridge Type"
        error={errors.fridgeType}
      />
      <SelectWithIcon
        icon="fa-industry"
        name="fridgeBrand"
        value={formData.fridgeBrand}
        onChange={handleInputChange}
        options={fridgeBrands}
        required
        label="Fridge Brand"
        error={errors.fridgeBrand}
      />
      <InputWithIcon
        icon="fa-exclamation-circle"
        type="textarea"
        name="faultDescription"
        value={formData.faultDescription}
        onChange={handleInputChange}
        placeholder="Describe the fault"
        required
        label="Fault Description"
        error={errors.faultDescription}
      />
    </>
  );
};

export default RepairForm;
