import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logos/Fridge Repair Transparent.png';

function AdminLogin() {
    const navigate = useNavigate();
    const [credentials, setCredentials] = useState({
        email: '',
        password: ''
    });
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (e) => {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setIsLoading(true);

        try {
            // First authenticate with our user system
            const userAuthResponse = await fetch('/api/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify(credentials)
            });

            const userAuthData = await userAuthResponse.json();

            if (!userAuthResponse.ok || !userAuthData.success) {
                throw new Error(userAuthData.message || 'Authentication failed');
            }

            // Store the JWT token
            if (userAuthData.token) {
                localStorage.setItem('auth_token', userAuthData.token);

                // Then proceed with ServiceM8 authentication
                const serviceM8Response = await fetch('/api/auth/servicem8/login', {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Authorization': `Bearer ${userAuthData.token}`
                    }
                });

                if (!serviceM8Response.ok) {
                    throw new Error('ServiceM8 authentication failed');
                }

                const serviceM8Data = await serviceM8Response.json();
                
                if (serviceM8Data.authUrl) {
                    // Redirect to ServiceM8 OAuth flow
                    window.location.href = serviceM8Data.authUrl;
                } else if (serviceM8Data.success) {
                    // Already authenticated with ServiceM8, redirect to admin panel
                    navigate('/admin/bookings');
                } else {
                    throw new Error('ServiceM8 authentication failed');
                }
            } else {
                throw new Error('No authentication token received');
            }
        } catch (error) {
            console.error('Login error:', error);
            localStorage.removeItem('auth_token');
            setError(error.message || 'Authentication failed');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <div className="flex justify-center">
                    <img
                        src={logo}
                        alt="Local Fridge Repair Logo"
                        className="w-auto h-48 max-w-none"
                        style={{ marginTop: '-50px', marginLeft: '-25px' }}
                    />
                </div>
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    Admin Login
                </h2>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    {error && (
                        <div className="mb-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                            {error}
                        </div>
                    )}

                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email Address
                            </label>
                            <div className="mt-1">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    required
                                    value={credentials.email}
                                    onChange={handleInputChange}
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                Password
                            </label>
                            <div className="mt-1">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    required
                                    value={credentials.password}
                                    onChange={handleInputChange}
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                />
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                disabled={isLoading}
                                className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                                    isLoading ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                            >
                                {isLoading ? 'Signing in...' : 'Sign in'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AdminLogin;
