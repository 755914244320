import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import locations from '../data/locations';

const ServiceTypeTemplate = ({ serviceType, title, description, openBookingForm }) => {
  const [activeLocation, setActiveLocation] = useState(null);
  const [activeRegion, setActiveRegion] = useState(null);
  const formattedServiceType = serviceType.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase());

  const commonProblems = [
    { problem: "Temperature inconsistencies", description: "Uneven cooling or freezing, leading to food safety issues" },
    { problem: "Unusual noises or vibrations", description: "Indicating potential mechanical failures or loose components" },
    { problem: "Excessive frost buildup", description: "Reducing efficiency and storage space" },
    { problem: "Water leakage", description: "Causing potential slip hazards and equipment damage" },
    { problem: "Failure to start or stay running", description: "Resulting in complete loss of cooling functionality" }
  ];

  const repairServices = [
    { service: "Comprehensive diagnostics", description: "Thorough examination to identify all issues" },
    { service: "Compressor repair and replacement", description: "Addressing the heart of your cooling system" },
    { service: "Thermostat calibration and replacement", description: "Ensuring accurate temperature control" },
    { service: "Refrigerant leak detection and recharge", description: "Maintaining optimal cooling performance" },
    { service: "Condenser and evaporator coil cleaning and repair", description: "Improving efficiency and air quality" },
    { service: "Door seal replacement", description: "Preventing cold air loss and energy waste" },
    { service: "Electrical system troubleshooting and repair", description: "Resolving power and control issues" }
  ];

  const benefits = [
    { title: "24/7 Emergency Services", description: "We're always available to handle urgent repair needs" },
    { title: "Experienced Engineers", description: "Our team has years of experience with all major brands" },
    { title: "Fast Response Times", description: "We aim to be on-site within hours of your call" },
    { title: "Transparent Pricing", description: "No hidden fees or surprise charges" },
    { title: "Quality Parts", description: "We use only high-quality, manufacturer-approved parts" },
    { title: "Comprehensive Warranty", description: "All our repairs come with a satisfaction guarantee" }
  ];

  const handleLocationClick = (location) => {
    setActiveLocation(location === activeLocation ? null : location);
    setActiveRegion(null);
  };

  const handleRegionClick = (region) => {
    setActiveRegion(region === activeRegion ? null : region);
  };

  const generateServiceLink = (location, region, town) => {
    const locationSlug = location.name.toLowerCase().replace(/ /g, '-');
    const regionSlug = region.name.toLowerCase().replace(/ /g, '-');
    const townSlug = town.name.toLowerCase().replace(/ /g, '-');
    const serviceSlug = serviceType.toLowerCase().replace(/[^a-z0-9]+/g, '-');

    if (location.name === region.name) {
      return `/service-areas/${locationSlug}/${townSlug}/${serviceSlug}-repair`;
    } else {
      return `/service-areas/${locationSlug}/${regionSlug}/${townSlug}/${serviceSlug}-repair`;
    }
  };

  const renderLocationLinks = (location) => (
    <div className="location mb-4">
      <button
        onClick={() => handleLocationClick(location.name)}
        className={`location-button w-full text-left px-4 py-2 bg-primary-500 text-white rounded-t-lg ${activeLocation === location.name ? 'bg-primary-600' : ''}`}
      >
        {location.name}
      </button>
      {activeLocation === location.name && (
        <div className="regions bg-white border border-primary-500 rounded-b-lg">
          {location.regions.map((region) => (
            <div key={region.name} className="region">
              {location.name !== region.name && (
                <button
                  onClick={() => handleRegionClick(region.name)}
                  className={`region-button w-full text-left px-4 py-2 hover:bg-gray-100 ${activeRegion === region.name ? 'bg-gray-200' : ''}`}
                >
                  {region.name}
                </button>
              )}
              {(activeRegion === region.name || location.name === region.name) && (
                <div className="towns pl-4">
                  {region.towns.map((town) => (
                    <div key={town.name} className="town">
                      <Link 
                        to={generateServiceLink(location, region, town)}
                        className="town-button w-full text-left px-4 py-2 hover:bg-gray-100 block text-primary-500 hover:text-primary-700"
                      >
                        {town.name}
                      </Link>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );

  return (
    <div className="bg-gray-100 min-h-screen">
      <Helmet>
        <title>{title || `${formattedServiceType} Repair Services | Local Fridge Repair`}</title>
        <meta name="description" content={description || `Professional ${formattedServiceType.toLowerCase()} repair services across various locations. Fast, reliable, and expert repairs for all types of ${formattedServiceType.toLowerCase()} units.`} />
        <meta name="keywords" content={`${formattedServiceType.toLowerCase()}, repair, maintenance, commercial refrigeration, Local Fridge Repair`} />
        <link rel="canonical" href={`https://localfridgerepair.com/services/${serviceType.toLowerCase().replace(/[^a-z0-9]+/g, '-')}`} />
      </Helmet>
      
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold text-center mb-8 text-primary-600">{title || `${formattedServiceType} Repair Services`}</h1>
        
        <p className="text-lg text-gray-700 text-center mb-12 max-w-3xl mx-auto">
          {description || `We offer expert repair services for all types of ${formattedServiceType.toLowerCase()} units. Our skilled engineers ensure your ${formattedServiceType.toLowerCase()} systems are running efficiently and reliably.`}
        </p>

        <section className="mb-16">
          <h2 className="text-3xl font-semibold mb-6 text-primary-600">Common {formattedServiceType} Problems We Fix</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {commonProblems.map((item, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md p-4">
                <h3 className="font-semibold text-lg mb-2 flex items-center">
                  <span className="text-primary-500 mr-2">✓</span>
                  {item.problem}
                </h3>
                <p className="text-gray-600">{item.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="mb-16">
          <h2 className="text-3xl font-semibold mb-6 text-primary-600">Our {formattedServiceType} Repair Services</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {repairServices.map((item, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md p-4">
                <h3 className="font-semibold text-lg mb-2 flex items-center">
                  <span className="text-primary-500 mr-2">✓</span>
                  {item.service}
                </h3>
                <p className="text-gray-600">{item.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="mb-16">
          <h2 className="text-3xl font-semibold mb-6 text-primary-600">Why Choose Us for {formattedServiceType} Repair?</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {benefits.map((item, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md p-4">
                <h3 className="font-semibold text-lg mb-2">{item.title}</h3>
                <p className="text-gray-600">{item.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="mb-16">
          <h2 className="text-3xl font-semibold mb-6 text-primary-600">Service Areas for {formattedServiceType} Repair</h2>
          <p className="text-lg text-gray-700 mb-8">We offer our {formattedServiceType.toLowerCase()} repair services across various locations. Select your area to learn more about our services near you:</p>
          
          <div className="service-areas">
            <div className="locations grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {locations.map(location => renderLocationLinks(location))}
            </div>
          </div>
        </section>

        <div className="mt-16 text-center bg-primary-100 p-8 rounded-lg shadow-md">
          <h2 className="text-3xl font-bold mb-6 text-primary-600">Ready to Book Your {formattedServiceType} Repair?</h2>
          <p className="text-lg text-gray-700 mb-8">
            Our expert engineers are ready to help. Book your repair service now and get your {formattedServiceType.toLowerCase()} back to optimal performance.
          </p>
          <button 
            onClick={() => {
              console.log('Book Your Repair Now button clicked in ServiceTypeTemplate');
              openBookingForm();
            }}
            className="inline-block bg-primary-500 text-white font-bold py-3 px-6 rounded-full text-lg hover:bg-primary-600 transition-colors duration-300 shadow-md"
          >
            Book Your Repair Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default ServiceTypeTemplate;
