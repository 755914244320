import React, { useState, useEffect } from 'react';
import { FaTimes, FaComments, FaClipboardList, FaBell } from 'react-icons/fa';

const NOTIFICATION_DURATION = 15000; // 15 seconds

const NotificationIcons = {
    chat: FaComments,
    booking: FaClipboardList,
    notification: FaBell
};

const NotificationColors = {
    chat: 'bg-blue-500',
    booking: 'bg-green-500',
    notification: 'bg-purple-500'
};

function CustomDesktopNotification({ type, title, message, onClose, onClick }) {
    const [isVisible, setIsVisible] = useState(true);
    const [isExiting, setIsExiting] = useState(false);
    
    const Icon = NotificationIcons[type] || FaBell;
    const bgColor = NotificationColors[type] || 'bg-gray-500';

    useEffect(() => {
        const timer = setTimeout(() => {
            handleClose();
        }, NOTIFICATION_DURATION);

        return () => clearTimeout(timer);
    }, []);

    const handleClose = () => {
        setIsExiting(true);
        setTimeout(() => {
            setIsVisible(false);
            onClose?.();
        }, 300); // Match transition duration
    };

    const handleClick = () => {
        onClick?.();
        handleClose();
    };

    if (!isVisible) return null;

    return (
        <div 
            className={`w-96 bg-white rounded-lg shadow-lg transform transition-all duration-300 ${
                isExiting ? 'translate-x-full opacity-0' : 'translate-x-0 opacity-100'
            }`}
            role="alert"
        >
            <div className="relative p-4">
                <div className="flex items-start">
                    <div className={`flex-shrink-0 p-2 ${bgColor} rounded-lg`}>
                        <Icon className="w-6 h-6 text-white" />
                    </div>
                    <div className="ml-3 w-0 flex-1">
                        <p className="text-sm font-medium text-gray-900">{title}</p>
                        <p className="mt-1 text-sm text-gray-500">{message}</p>
                        {onClick && (
                            <button
                                onClick={handleClick}
                                className="mt-2 text-sm font-medium text-blue-600 hover:text-blue-500"
                            >
                                View Details →
                            </button>
                        )}
                    </div>
                    <div className="ml-4 flex-shrink-0 flex">
                        <button
                            className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none"
                            onClick={handleClose}
                        >
                            <span className="sr-only">Close</span>
                            <FaTimes className="h-5 w-5" />
                        </button>
                    </div>
                </div>
                <div 
                    className={`absolute bottom-0 left-0 h-1 ${bgColor} transition-all duration-300`} 
                    style={{ 
                        width: '100%',
                        animation: `shrink ${NOTIFICATION_DURATION}ms linear forwards`
                    }}
                />
            </div>
        </div>
    );
}

// Add the animation to the global styles
if (!document.getElementById('notification-styles')) {
    const style = document.createElement('style');
    style.id = 'notification-styles';
    style.textContent = `
        @keyframes shrink {
            from { width: 100%; }
            to { width: 0%; }
        }
    `;
    document.head.appendChild(style);
}

export default CustomDesktopNotification;
