import React from 'react';
import AssetDocuments from './AssetDocuments';

const ClientView = ({ client, adminPassword, onBack }) => {
  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <button
          onClick={onBack}
          className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        >
          <svg className="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
          </svg>
          Back to Clients
        </button>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Client Information</h3>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Company Name</dt>
              <dd className="mt-1 text-sm text-gray-900">{client.name}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Email</dt>
              <dd className="mt-1 text-sm text-gray-900">{client.email}</dd>
            </div>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">Total Assets</dt>
              <dd className="mt-1 text-sm text-gray-900">{client.assetCount}</dd>
            </div>
          </dl>
        </div>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Assets</h3>
        </div>
        <div className="border-t border-gray-200">
          <ul className="divide-y divide-gray-200">
            {client.assets.map((asset) => (
              <li key={asset.uuid} className="px-4 py-5 sm:px-6">
                <div className="space-y-4">
                  <div>
                    <h4 className="text-md font-medium text-gray-900">{asset.name}</h4>
                    <div className="mt-2 flex flex-wrap gap-4 text-sm text-gray-500">
                      <div>
                        <span className="font-medium">Model:</span>{' '}
                        {asset.modelNumber || 'N/A'}
                      </div>
                      <div>
                        <span className="font-medium">Serial:</span>{' '}
                        {asset.serialNumber || 'N/A'}
                      </div>
                    </div>
                  </div>
                  <AssetDocuments
                    asset={asset}
                    adminPassword={adminPassword}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ClientView;
