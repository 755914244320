import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logos/Fridge Repair Transparent.png';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white">
      <div className="container mx-auto px-4 py-8 sm:py-12">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
          {/* Company Info */}
          <div className="text-center sm:text-left">
            <div className="flex justify-center sm:justify-start mx-auto sm:mx-0">
              <div className="relative w-56 h-28 sm:w-64 sm:h-32 overflow-hidden">
                <img 
                  src={logo} 
                  alt="Local Fridge Repair Logo" 
                  className="absolute left-1/2 sm:left-0 transform -translate-x-1/2 sm:-translate-x-0 w-auto h-64 sm:h-72 max-w-none"
                  style={{ 
                    marginTop: '-90px',
                    '@media (min-width: 640px)': {
                      marginTop: '-110px',
                      marginLeft: '-40px'
                    }
                  }}
                />
              </div>
            </div>
            <p className="text-gray-400 text-sm mt-4 max-w-sm mx-auto sm:mx-0">
              Professional commercial refrigeration repairs across London and surrounding areas.
              Available 24/7 for emergency repairs.
            </p>
          </div>

          {/* Quick Links */}
          <div className="text-center sm:text-left">
            <h3 className="text-lg font-semibold mb-4 text-primary-500">Quick Links</h3>
            <ul className="space-y-3 sm:space-y-2">
              <li>
                <Link to="/" className="text-gray-400 hover:text-primary-500 transition duration-300">Home</Link>
              </li>
              <li>
                <Link to="/services" className="text-gray-400 hover:text-primary-500 transition duration-300">Services</Link>
              </li>
              <li>
                <Link to="/service-areas" className="text-gray-400 hover:text-primary-500 transition duration-300">Coverage Areas</Link>
              </li>
              <li>
                <Link to="/ppm" className="text-gray-400 hover:text-primary-500 transition duration-300">PPM Portal</Link>
              </li>
            </ul>
          </div>

          {/* Contact Info */}
          <div className="text-center sm:text-left">
            <h3 className="text-lg font-semibold mb-4 text-primary-500">Contact Us</h3>
            <div className="space-y-3 sm:space-y-2">
              <p className="text-gray-400">
                <i className="fas fa-phone text-primary-500 mr-2"></i>
                <a href="tel:+443308183932" className="hover:text-primary-500 transition duration-300">0330 818 3932</a>
              </p>
              <p className="text-gray-400">
                <i className="fas fa-envelope text-primary-500 mr-2"></i>
                <a href="mailto:service@localfridgerepair.com" className="hover:text-primary-500 transition duration-300">service@localfridgerepair.com</a>
              </p>
              <p className="text-gray-400">
                <i className="fas fa-clock text-primary-500 mr-2"></i>
                24/7 Emergency Service
              </p>
            </div>
          </div>

          {/* Service Areas */}
          <div className="text-center sm:text-left">
            <h3 className="text-lg font-semibold mb-4 text-primary-500">Service Areas</h3>
            <ul className="space-y-3 sm:space-y-2">
              <li>
                <Link to="/service-areas/london" className="text-gray-400 hover:text-primary-500 transition duration-300">London</Link>
              </li>
              <li>
                <Link to="/service-areas/essex" className="text-gray-400 hover:text-primary-500 transition duration-300">Essex</Link>
              </li>
              <li>
                <Link to="/service-areas/kent" className="text-gray-400 hover:text-primary-500 transition duration-300">Kent</Link>
              </li>
              <li>
                <Link to="/service-areas/surrey" className="text-gray-400 hover:text-primary-500 transition duration-300">Surrey</Link>
              </li>
              <li>
                <Link to="/service-areas/hertfordshire" className="text-gray-400 hover:text-primary-500 transition duration-300">Hertfordshire</Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-gray-800 mt-8 pt-8">
          <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
            <p className="text-gray-400 text-sm text-center sm:text-left">
              © {new Date().getFullYear()} Local Fridge Repair. All rights reserved.
            </p>
            <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0">
              <Link to="/privacy-policy" className="text-gray-400 hover:text-primary-500 transition duration-300 text-sm sm:mx-3">
                Privacy Policy
              </Link>
              <Link to="/terms-of-service" className="text-gray-400 hover:text-primary-500 transition duration-300 text-sm sm:mx-3">
                Terms of Service
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
