import React, { useState, useEffect, useRef } from 'react';
import { Outlet, useNavigate, Link, useLocation } from 'react-router-dom';
import { FaClipboardList, FaRoute, FaTasks, FaGoogle, FaCalendarCheck, FaUsers, FaComments, FaBell } from 'react-icons/fa';
import NotificationSystem from './NotificationSystem';
import { initNotificationManager, showNotification } from './NotificationManager';

function AdminLayout() {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [lastRefresh, setLastRefresh] = useState(Date.now());
    const [totalUnreadCount, setTotalUnreadCount] = useState(0);
    const [unreadBookingsCount, setUnreadBookingsCount] = useState(0);
    const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
    const [showNotifications, setShowNotifications] = useState(false);
    const [notificationPermission, setNotificationPermission] = useState('default');
    const authCheckTimeout = useRef(null);
    const abortController = useRef(null);
    const lastAuthCheck = useRef(Date.now());
    const authCheckInterval = useRef(null);
    const chatPollInterval = useRef(null);
    const bookingsPollInterval = useRef(null);
    const notificationsPollInterval = useRef(null);
    const isAuthenticating = useRef(false);
    const previousCounts = useRef({
        notifications: 0,
        chat: 0,
        bookings: 0
    });

    // Initialize notification manager on mount
    useEffect(() => {
        initNotificationManager();
    }, []);

    // Request notification permission on mount
    useEffect(() => {
        const setupNotifications = async () => {
            try {
                // Force an immediate permission request
                if ('Notification' in window) {
                    console.log('Requesting notification permission...');
                    const permission = await Notification.requestPermission();
                    console.log('Initial permission result:', permission);
                    setNotificationPermission(permission);
                } else {
                    console.log('Notifications not supported');
                    setNotificationPermission('denied');
                }
            } catch (error) {
                console.error('Error setting up notifications:', error);
                setNotificationPermission('denied');
            }
        };

        setupNotifications();
    }, []);

    const requestPermission = async () => {
        try {
            if (!('Notification' in window)) {
                console.log('Notifications not supported');
                return;
            }

            console.log('Requesting notification permission...');
            const permission = await Notification.requestPermission();
            console.log('Permission result:', permission);
            setNotificationPermission(permission);
        } catch (error) {
            console.error('Error requesting permission:', error);
        }
    };

    const showDesktopNotification = async (type, message) => {
        console.log('Attempting to show notification:', { type, message });

        const titles = {
            chat: 'New Chat Message',
            booking: 'New Booking',
            notification: 'New Notification'
        };

        const urls = {
            chat: '/admin/chat',
            booking: '/admin/bookings',
            notification: null
        };

        showNotification(
            type,
            titles[type] || 'Notification',
            message,
            urls[type] ? () => navigate(urls[type]) : null
        );
    };

    const isActiveLink = (path) => {
        return location.pathname.startsWith(path) ? 'border-blue-500 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700';
    };

    const handleRefresh = () => {
        setLastRefresh(Date.now());
    };

    const getAuthHeaders = () => {
        const token = localStorage.getItem('auth_token');
        return {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
    };

    const handleAuthError = () => {
        if (authCheckInterval.current) clearInterval(authCheckInterval.current);
        if (authCheckTimeout.current) clearTimeout(authCheckTimeout.current);
        if (abortController.current) abortController.current.abort();
        if (chatPollInterval.current) clearInterval(chatPollInterval.current);
        if (bookingsPollInterval.current) clearInterval(bookingsPollInterval.current);
        if (notificationsPollInterval.current) clearInterval(notificationsPollInterval.current);

        localStorage.removeItem('auth_token');
        setIsLoading(false);
        navigate('/admin/login');
    };

    const fetchUnreadCount = async () => {
        try {
            const token = localStorage.getItem('auth_token');
            if (!token) return;

            const response = await fetch('/api/chat/rooms', {
                headers: getAuthHeaders()
            });

            if (!response.ok) return;

            const data = await response.json();
            if (!data.success) return;

            const total = data.rooms.reduce((sum, room) => sum + (room.unread_count || 0), 0);

            if (total > previousCounts.current.chat) {
                const newMessages = total - previousCounts.current.chat;
                console.log('New chat messages detected:', newMessages);
                await showDesktopNotification('chat', `You have ${newMessages} new message${newMessages > 1 ? 's' : ''}`);
            }
            previousCounts.current.chat = total;

            setTotalUnreadCount(total);
        } catch (error) {
            console.error('Error fetching unread count:', error);
        }
    };

    const fetchUnreadBookingsCount = async () => {
        try {
            const token = localStorage.getItem('auth_token');
            if (!token) return;

            const response = await fetch('/api/admin/bookings/unread-count', {
                headers: getAuthHeaders()
            });

            if (!response.ok) {
                if (response.status === 401 || response.status === 403) {
                    handleAuthError();
                    return;
                }
                throw new Error('Failed to fetch unread bookings count');
            }

            const data = await response.json();
            if (!data.success) {
                throw new Error(data.message || 'Failed to fetch unread bookings count');
            }

            if (data.count > previousCounts.current.bookings) {
                const newBookings = data.count - previousCounts.current.bookings;
                console.log('New bookings detected:', newBookings);
                await showDesktopNotification('booking', `You have ${newBookings} new booking${newBookings > 1 ? 's' : ''}`);
            }
            previousCounts.current.bookings = data.count;

            setUnreadBookingsCount(data.count || 0);
        } catch (error) {
            console.error('Error fetching unread bookings count:', error);
        }
    };

    const fetchUnreadNotificationsCount = async () => {
        try {
            const token = localStorage.getItem('auth_token');
            if (!token) return;

            const [notificationsResponse, chatResponse] = await Promise.all([
                fetch('/api/admin/notifications/unread-count', {
                    headers: getAuthHeaders()
                }),
                fetch('/api/chat/rooms', {
                    headers: getAuthHeaders()
                })
            ]);

            if (!notificationsResponse.ok || !chatResponse.ok) {
                if (notificationsResponse.status === 401 || notificationsResponse.status === 403 ||
                    chatResponse.status === 401 || chatResponse.status === 403) {
                    handleAuthError();
                    return;
                }
                throw new Error('Failed to fetch unread counts');
            }

            const [notificationsData, chatData] = await Promise.all([
                notificationsResponse.json(),
                chatResponse.json()
            ]);

            if (!notificationsData.success || !chatData.success) {
                throw new Error('Failed to fetch unread counts');
            }

            const unreadChatCount = chatData.rooms.reduce((sum, room) => sum + (room.unread_count || 0), 0);

            if (notificationsData.count > previousCounts.current.notifications) {
                const newNotifications = notificationsData.count - previousCounts.current.notifications;
                console.log('New notifications detected:', newNotifications);
                await showDesktopNotification('notification', `You have ${newNotifications} new notification${newNotifications > 1 ? 's' : ''}`);
            }
            previousCounts.current.notifications = notificationsData.count;

            setUnreadNotificationsCount((notificationsData.count || 0) + unreadChatCount);
        } catch (error) {
            console.error('Error fetching unread counts:', error);
        }
    };

    useEffect(() => {
        fetchUnreadCount();
        fetchUnreadBookingsCount();
        fetchUnreadNotificationsCount();

        if (!location.pathname.startsWith('/admin/chat')) {
            chatPollInterval.current = setInterval(fetchUnreadCount, 3000);
        }
        if (!location.pathname.startsWith('/admin/bookings')) {
            bookingsPollInterval.current = setInterval(fetchUnreadBookingsCount, 3000);
        }
        if (!showNotifications) {
            notificationsPollInterval.current = setInterval(fetchUnreadNotificationsCount, 3000);
        }

        return () => {
            if (chatPollInterval.current) clearInterval(chatPollInterval.current);
            if (bookingsPollInterval.current) clearInterval(bookingsPollInterval.current);
            if (notificationsPollInterval.current) clearInterval(notificationsPollInterval.current);
        };
    }, [location.pathname, showNotifications]);

    const checkAuth = async () => {
        if (isAuthenticating.current) return;

        try {
            isAuthenticating.current = true;
            const token = localStorage.getItem('auth_token');
            if (!token) {
                handleAuthError();
                return;
            }

            if (abortController.current) {
                abortController.current.abort();
            }
            abortController.current = new AbortController();

            const response = await fetch('/api/auth/servicem8/verify', {
                method: 'GET',
                headers: getAuthHeaders(),
                credentials: 'include',
                signal: abortController.current.signal
            });

            if (!response.ok) {
                const data = await response.json().catch(() => ({}));
                throw new Error(data.message || 'Authentication failed');
            }

            const data = await response.json();
            if (!data.success) {
                throw new Error(data.message || 'Authentication failed');
            }

            lastAuthCheck.current = Date.now();
            setIsLoading(false);
        } catch (error) {
            if (error.name === 'AbortError') return;
            console.error('Auth check error:', error);
            handleAuthError();
        } finally {
            isAuthenticating.current = false;
            abortController.current = null;
        }
    };

    useEffect(() => {
        const initialCheck = setTimeout(() => {
            checkAuth();
        }, 1000);

        authCheckInterval.current = setInterval(() => {
            const now = Date.now();
            const timeSinceLastCheck = now - lastAuthCheck.current;
            if (timeSinceLastCheck >= 30000) {
                checkAuth();
            }
        }, 15 * 60 * 1000);

        return () => {
            clearTimeout(initialCheck);
            if (authCheckInterval.current) clearInterval(authCheckInterval.current);
            if (authCheckTimeout.current) clearTimeout(authCheckTimeout.current);
            if (abortController.current) abortController.current.abort();
        };
    }, [navigate]);

    const handleLogout = async () => {
        try {
            const token = localStorage.getItem('auth_token');
            if (token) {
                await fetch('/api/auth/servicem8/logout', {
                    method: 'POST',
                    headers: getAuthHeaders(),
                    credentials: 'include'
                });
            }
        } catch (error) {
            console.error('Logout error:', error);
        } finally {
            handleAuthError();
        }
    };

    if (isLoading) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-gray-100">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    const isFullWidthRoute = location.pathname === '/admin/chat' || 
                            location.pathname === '/admin/bookings' || 
                            location.pathname.startsWith('/admin/bookings/');

    return (
        <div className="min-h-screen bg-gray-100">
            <nav className="bg-white shadow-sm">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between h-16">
                        <div className="flex">
                            <div className="flex-shrink-0 flex items-center">
                                <Link to="/admin" className="text-xl font-bold text-gray-800">
                                    Admin Panel
                                </Link>
                            </div>
                            <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                                <Link
                                    to="/admin/bookings"
                                    className={`inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium relative ${isActiveLink('/admin/bookings')}`}
                                >
                                    <FaClipboardList className="mr-2" /> Bookings
                                    {unreadBookingsCount > 0 && !location.pathname.startsWith('/admin/bookings') && (
                                        <span className="absolute top-3 -right-8 bg-red-500 text-white text-xs min-w-[1.25rem] h-5 flex items-center justify-center rounded-full px-1">
                                            {unreadBookingsCount}
                                        </span>
                                    )}
                                </Link>
                                <Link
                                    to="/admin/routing"
                                    className={`inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${isActiveLink('/admin/routing')}`}
                                >
                                    <FaRoute className="mr-2" /> Routing
                                </Link>
                                <Link
                                    to="/admin/tasks"
                                    className={`inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${isActiveLink('/admin/tasks')}`}
                                >
                                    <FaTasks className="mr-2" /> Tasks
                                </Link>
                                <Link
                                    to="/admin/ads"
                                    className={`inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${isActiveLink('/admin/ads')}`}
                                >
                                    <FaGoogle className="mr-2" /> Google Ads
                                </Link>
                                <Link
                                    to="/admin/ppm"
                                    className={`inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${isActiveLink('/admin/ppm')}`}
                                >
                                    <FaCalendarCheck className="mr-2" /> PPM
                                </Link>
                                <Link
                                    to="/admin/users"
                                    className={`inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${isActiveLink('/admin/users')}`}
                                >
                                    <FaUsers className="mr-2" /> Users
                                </Link>
                                <Link
                                    to="/admin/chat"
                                    className={`inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium relative ${isActiveLink('/admin/chat')}`}
                                >
                                    <FaComments className="mr-2" /> Chat
                                    {totalUnreadCount > 0 && !location.pathname.startsWith('/admin/chat') && (
                                        <span className="absolute top-3 -right-8 bg-red-500 text-white text-xs min-w-[1.25rem] h-5 flex items-center justify-center rounded-full px-1">
                                            {totalUnreadCount}
                                        </span>
                                    )}
                                </Link>
                            </div>
                        </div>
                        <div className="flex items-center space-x-4">
                            <button
                                onClick={() => setShowNotifications(!showNotifications)}
                                className="relative p-2 text-gray-600 hover:text-gray-800 focus:outline-none"
                            >
                                <FaBell className="h-6 w-6" />
                                {unreadNotificationsCount > 0 && !showNotifications && (
                                    <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs min-w-[1.25rem] h-5 flex items-center justify-center rounded-full px-1">
                                        {unreadNotificationsCount}
                                    </span>
                                )}
                            </button>
                            {notificationPermission === 'default' && (
                                <button
                                    onClick={requestPermission}
                                    className="inline-flex items-center px-3 py-2 border border-blue-500 text-sm font-medium rounded-md text-blue-500 bg-white hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                >
                                    <FaBell className="mr-2 h-4 w-4" />
                                    Enable Notifications
                                </button>
                            )}
                            <button
                                onClick={handleLogout}
                                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                            >
                                Logout
                            </button>
                        </div>
                    </div>
                </div>
            </nav>

            <div className={`${isFullWidthRoute ? 'h-[calc(100vh-4rem)]' : ''}`}>
                <div className="flex h-full">
                    {/* Notifications Panel */}
                    <div className={`${showNotifications ? 'w-96' : 'w-0'} transition-all duration-300 overflow-hidden border-r border-gray-200 bg-white h-[calc(100vh-4rem)]`}>
                        {showNotifications && <NotificationSystem lastRefresh={lastRefresh} />}
                    </div>

                    {/* Main Content */}
                    <div className="flex-1">
                        {isFullWidthRoute ? (
                            <div className="h-full bg-white">
                                <Outlet context={{ lastRefresh, onRefresh: handleRefresh }} />
                            </div>
                        ) : (
                            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                                <div className="px-4 py-6 sm:px-0">
                                    <div className="bg-white rounded-lg shadow p-6">
                                        <Outlet context={{ lastRefresh, onRefresh: handleRefresh }} />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminLayout;
