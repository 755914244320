import React, { useState } from 'react';
import DocumentUpload from './DocumentUpload';
import DocumentList from './DocumentList';

const AssetDocuments = ({ asset, adminPassword, authCode, isAdmin = false }) => {
  const [showUpload, setShowUpload] = useState(false);

  const handleUploadComplete = () => {
    setShowUpload(false);
  };

  return (
    <div className="mt-6 space-y-6">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-medium text-gray-900">Documents</h3>
        {isAdmin && (
          <button
            onClick={() => setShowUpload(!showUpload)}
            className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md ${
              showUpload
                ? 'text-gray-700 bg-gray-100 hover:bg-gray-200'
                : 'text-white bg-blue-600 hover:bg-blue-700'
            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200`}
          >
            {showUpload ? (
              <>
                <svg className="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
                Cancel Upload
              </>
            ) : (
              <>
                <svg className="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
                </svg>
                Upload Document
              </>
            )}
          </button>
        )}
      </div>

      {isAdmin && showUpload && (
        <div className="bg-gray-50 p-4 rounded-lg">
          <DocumentUpload
            assetUuid={asset.uuid}
            adminPassword={adminPassword}
            onUploadComplete={handleUploadComplete}
          />
        </div>
      )}

      <DocumentList
        assetUuid={asset.uuid}
        adminPassword={adminPassword}
        authCode={authCode}
        isAdmin={isAdmin}
        onDocumentRemoved={() => {}}
      />
    </div>
  );
};

export default AssetDocuments;
