import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

function StaffSelector({ value, onChange, className = '' }) {
  const navigate = useNavigate();
  const [staff, setStaff] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const abortController = useRef(null);

  const handleAuthError = () => {
    localStorage.removeItem('auth_token');
    navigate('/admin/login');
  };

  const getAuthHeaders = () => {
    const token = localStorage.getItem('auth_token');
    return {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
  };

  const fetchStaff = async () => {
    try {
      const token = localStorage.getItem('auth_token');
      if (!token) {
        handleAuthError();
        return;
      }

      // Cancel any ongoing request
      if (abortController.current) {
        abortController.current.abort();
      }
      abortController.current = new AbortController();

      const response = await fetch('/api/admin/staff', {
        credentials: 'include',
        headers: getAuthHeaders(),
        signal: abortController.current.signal
      });
      
      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          handleAuthError();
          return;
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();

      // Filter active staff members and sort by name
      const activeStaff = data.filter(member => member.active === "1" || member.active === 1);
      const sortedStaff = activeStaff.sort((a, b) => {
        const nameA = `${a.first || ''} ${a.last || ''}`.trim().toLowerCase();
        const nameB = `${b.first || ''} ${b.last || ''}`.trim().toLowerCase();
        return nameA.localeCompare(nameB);
      });

      setStaff(sortedStaff);
      setError(null);
    } catch (err) {
      if (err.name === 'AbortError') {
        return; // Ignore aborted requests
      }
      console.error('Error fetching staff:', err);
      setError(err.message);
    } finally {
      setLoading(false);
      abortController.current = null;
    }
  };

  useEffect(() => {
    fetchStaff();

    return () => {
      if (abortController.current) {
        abortController.current.abort();
      }
    };
  }, []);

  if (loading) {
    return (
      <select
        disabled
        className={`w-full px-4 py-2 border border-gray-300 rounded-lg bg-gray-50 ${className}`}
      >
        <option>Loading staff...</option>
      </select>
    );
  }

  if (error) {
    return (
      <select
        disabled
        className={`w-full px-4 py-2 border border-red-300 rounded-lg bg-red-50 ${className}`}
      >
        <option>Error loading staff</option>
      </select>
    );
  }

  return (
    <select
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className={`w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 ${className}`}
    >
      <option value="">Select staff member...</option>
      {staff.map((member) => {
        const firstName = member.first || '';
        const lastName = member.last || '';
        const displayName = `${firstName} ${lastName}`.trim();
        
        return (
          <option key={member.uuid} value={member.uuid}>
            {displayName || 'Unnamed Staff Member'}
          </option>
        );
      })}
    </select>
  );
}

export default StaffSelector;
