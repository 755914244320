import React, { useState, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import CustomDesktopNotification from './CustomDesktopNotification';

// Event-based notification system
const notificationEvents = new EventTarget();
const NOTIFICATION_EVENT = 'add-notification';

// Create audio context for sound
let audioContext = null;
let hasInteracted = false;

function initAudioContext() {
    if (!audioContext) {
        audioContext = new (window.AudioContext || window.webkitAudioContext)();
    }
    
    if (audioContext.state === 'suspended') {
        audioContext.resume();
    }
}

function playNotificationSound() {
    try {
        if (!hasInteracted) {
            console.log('Waiting for user interaction before playing sound');
            return;
        }

        if (!audioContext || audioContext.state === 'suspended') {
            initAudioContext();
        }

        // Create oscillator
        const oscillator = audioContext.createOscillator();
        const gainNode = audioContext.createGain();

        // Configure sound
        oscillator.type = 'sine';
        oscillator.frequency.setValueAtTime(880, audioContext.currentTime); // A5 note
        gainNode.gain.setValueAtTime(0.1, audioContext.currentTime);
        gainNode.gain.exponentialRampToValueAtTime(0.001, audioContext.currentTime + 0.5);

        // Connect nodes
        oscillator.connect(gainNode);
        gainNode.connect(audioContext.destination);

        // Play sound
        oscillator.start(audioContext.currentTime);
        oscillator.stop(audioContext.currentTime + 0.5);

        console.log('Sound played successfully');
    } catch (error) {
        console.error('Error playing sound:', error);
    }
}

// Handle user interaction
function handleInteraction() {
    hasInteracted = true;
    initAudioContext();
    document.removeEventListener('click', handleInteraction);
    document.removeEventListener('keydown', handleInteraction);
    document.removeEventListener('touchstart', handleInteraction);
}

// Add interaction listeners
document.addEventListener('click', handleInteraction);
document.addEventListener('keydown', handleInteraction);
document.addEventListener('touchstart', handleInteraction);

function NotificationManager() {
    const [notifications, setNotifications] = useState([]);

    const addNotification = useCallback((type, title, message, onClick) => {
        console.log('Adding notification:', { type, title, message });
        const id = Date.now();

        // Try to play sound
        playNotificationSound();

        setNotifications(prev => [...prev, { id, type, title, message, onClick }]);
        return id;
    }, []);

    const removeNotification = useCallback((id) => {
        console.log('Removing notification:', id);
        setNotifications(prev => prev.filter(notification => notification.id !== id));
    }, []);

    useEffect(() => {
        const handleNotification = (event) => {
            console.log('Notification event received:', event.detail);
            const { type, title, message, onClick } = event.detail;
            addNotification(type, title, message, onClick);
        };

        notificationEvents.addEventListener(NOTIFICATION_EVENT, handleNotification);
        return () => {
            notificationEvents.removeEventListener(NOTIFICATION_EVENT, handleNotification);
        };
    }, [addNotification]);

    // Create a portal directly to body
    return ReactDOM.createPortal(
        <div 
            className="fixed bottom-4 right-4 z-[9999] flex flex-col-reverse gap-4"
            style={{ pointerEvents: 'none' }}
        >
            {notifications.map(({ id, type, title, message, onClick }) => (
                <div key={id} style={{ pointerEvents: 'auto' }}>
                    <CustomDesktopNotification
                        type={type}
                        title={title}
                        message={message}
                        onClick={onClick}
                        onClose={() => removeNotification(id)}
                    />
                </div>
            ))}
        </div>,
        document.body
    );
}

export function showNotification(type, title, message, onClick) {
    console.log('showNotification called:', { type, title, message });
    const event = new CustomEvent(NOTIFICATION_EVENT, {
        detail: { type, title, message, onClick }
    });
    notificationEvents.dispatchEvent(event);
}

let isInitialized = false;

export function initNotificationManager() {
    if (!isInitialized) {
        console.log('Initializing NotificationManager');

        // Create container and add to body
        const container = document.createElement('div');
        container.id = 'notification-manager-root';
        document.body.appendChild(container);

        // Create root and render
        const root = ReactDOM.createRoot(container);
        root.render(<NotificationManager />);

        isInitialized = true;
        console.log('NotificationManager initialized');
    }
}

export default NotificationManager;
