import React from 'react';

const BrandItem = ({ name, logo }) => (
  <div className="bg-white rounded-lg p-4 shadow-md flex items-center justify-center transition-transform hover:scale-105">
    <div className="w-32 h-24 flex items-center justify-center">
      {logo ? (
        <img src={logo} alt={name} className="max-w-full max-h-full object-contain" />
      ) : (
        <span className="text-base font-semibold text-gray-800 text-center break-words">{name}</span>
      )}
    </div>
  </div>
);

const BrandsWeService = () => {
  const brands = [
    "Foster", "Williams", "True", "Polar", "Gram", "Electrolux", "Scotsman",
    "Hoshizaki", "Fagor", "Infrico", "Atosa", "Tefcold", "Blizzard"
  ];

  const importLogo = (brand) => {
    try {
      return require(`../assets/logos/brand-logos/${brand.toLowerCase().replace(' ', '-')}.webp`);
    } catch (err) {
      return null;
    }
  };

  return (
    <section className="py-16 bg-gray-50" id="brands">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12 text-primary-600">Commercial Refrigeration Brands We Service</h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
          {brands.map((brand, index) => (
            <BrandItem key={index} name={brand} logo={importLogo(brand)} />
          ))}
        </div>
        <p className="mt-12 text-center text-lg text-gray-600">
          Don't see your commercial refrigeration brand listed? We likely still service it!{' '}
          <a href="/contact" className="text-primary-500 hover:text-primary-700 font-semibold">
            Contact us
          </a>{' '}
          for information about your specific equipment.
        </p>
      </div>
    </section>
  );
};

export default BrandsWeService;