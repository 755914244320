import React from 'react';
import { FaCheckDouble, FaCheck } from 'react-icons/fa';

const ChatMessage = ({ message }) => {
    const messageTime = new Date(message.created_at).toLocaleTimeString();
    const isAdmin = message.sender_type === 'admin';

    return (
        <div className={`flex flex-col max-w-[70%] ${isAdmin ? 'ml-auto' : 'mr-auto'}`}>
            <div
                className={`rounded-lg px-4 py-2 shadow-sm ${
                    isAdmin 
                        ? 'bg-blue-500 text-white' 
                        : 'bg-white border border-gray-200 text-gray-800'
                }`}
            >
                <p className="text-sm break-words leading-relaxed">{message.message}</p>
            </div>
            <div className={`flex items-center gap-1 mt-1 ${
                isAdmin ? 'justify-end' : 'justify-start'
            }`}>
                <span className="text-xs text-gray-500">
                    {messageTime}
                </span>
                {isAdmin && (
                    <span className="text-xs text-gray-400">
                        {message.read_at ? <FaCheckDouble /> : <FaCheck />}
                    </span>
                )}
            </div>
        </div>
    );
};

export default ChatMessage;
