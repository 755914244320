import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Breadcrumbs from './Breadcrumbs';
import CTAButton from './CTAButton';
import InteractiveMap from './InteractiveMap';
import FAQSection from './FAQSection';
import WhyChooseUs from './WhyChooseUs';
import refrigerationTypes from '../config/refrigerationTypes';

const ServicePageTemplate = ({ location, region, town, service, townData, openBookingForm }) => {
  const formattedService = service.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase());

  const seoData = {
    title: `${formattedService} Repair in ${town}, ${region} | Local Fridge Repair`,
    description: `Expert ${formattedService.toLowerCase()} repair services in ${town}, ${region} & Surrounding Areas. Fast and reliable repairs for all brands and models. 24/7 emergency service available.`,
    keywords: [`${formattedService.toLowerCase()} repair`, town, region, location, 'refrigeration services', 'commercial refrigeration repair'],
    canonicalUrl: `https://refrigeration.repair/service-areas/${location.toLowerCase().replace(/ /g, '-')}${location !== region ? `/${region.toLowerCase().replace(/ /g, '-')}` : ''}/${town.toLowerCase().replace(/ /g, '-')}/${service.toLowerCase().replace(/[^a-z0-9]+/g, '-')}-repair`,
  };

  const content = {
    title: `${formattedService} Repair in ${town}, ${region} & Surrounding Areas`,
    metaDescription: seoData.description,
    mainContent: `
      <p>Looking for professional ${formattedService.toLowerCase()} repair services in ${town}? Local Fridge Repair offers expert commercial refrigeration solutions tailored to the unique needs of businesses in ${town}, ${region}. Our team of skilled engineers specializes in repairing, maintaining, and installing all types of ${formattedService.toLowerCase()} units, ensuring your equipment runs efficiently and reliably.</p>
      <p>With years of experience serving the ${town} area, we understand the critical role that properly functioning ${formattedService.toLowerCase()} units play in local businesses. Whether you run a busy restaurant, a local supermarket, or any other business that relies on refrigeration, we have the expertise to keep your ${formattedService.toLowerCase()} units in top condition.</p>
    `,
    reasons: [
      `Experienced and certified refrigeration engineers in ${town}`,
      `Fast response times across ${town} and surrounding areas`,
      `Comprehensive knowledge of local business needs in ${region}`,
      "24/7 emergency repair services available",
      "Use of high-quality, energy-efficient replacement parts",
      `Tailored maintenance plans for ${town} businesses`,
      "Excellent customer service and follow-up support"
    ],
    faqs: [
      { question: `How quickly can you respond to a ${formattedService.toLowerCase()} emergency in ${town}?`, answer: `We pride ourselves on our rapid response times for businesses in ${town}. In most cases, we can have an engineer at your location within 1-2 hours for emergency ${formattedService.toLowerCase()} repairs, minimising downtime for your business.` },
      { question: `Do you offer maintenance contracts for ${formattedService.toLowerCase()} units in ${town}?`, answer: `Yes, we offer customised maintenance contracts for businesses in ${town}. Regular maintenance of your ${formattedService.toLowerCase()} units can prevent unexpected breakdowns, extend equipment life, and improve energy efficiency, which is crucial for businesses in this area.` },
      { question: `Can you help improve the energy efficiency of my ${formattedService.toLowerCase()} unit in ${town}?`, answer: `Absolutely! We specialise in optimising ${formattedService.toLowerCase()} units for energy efficiency in ${town} businesses. This can include adjusting settings, replacing worn parts, and recommending upgrades to help reduce your energy costs and improve your environmental footprint.` },
      { question: `Are your engineers familiar with local health and safety regulations for ${formattedService.toLowerCase()} units in ${town}?`, answer: `Yes, our engineers are fully versed in all local and national regulations regarding commercial refrigeration in ${town} and ${region}. We ensure that all repairs and installations comply with current health and safety standards, including those specific to ${town}'s requirements.` }
    ]
  };

  const generateServiceLink = (serviceType) => {
    return `/service-areas/${location.toLowerCase().replace(/ /g, '-')}${location !== region ? `/${region.toLowerCase().replace(/ /g, '-')}` : ''}/${town.toLowerCase().replace(/ /g, '-')}/${serviceType.toLowerCase().replace(/[^a-z0-9]+/g, '-')}-repair`;
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <Helmet>
        <title>{seoData.title}</title>
        <meta name="description" content={seoData.description} />
        <meta name="keywords" content={seoData.keywords.join(', ')} />
        <link rel="canonical" href={seoData.canonicalUrl} />
      </Helmet>
      <div className="container mx-auto px-4 py-6 sm:py-8">
        <Breadcrumbs items={[
          { label: 'Service Areas', path: '/service-areas' },
          { label: location, path: `/service-areas/${location.toLowerCase().replace(/ /g, '-')}` },
          ...(location !== region ? [{ label: region, path: `/service-areas/${location.toLowerCase().replace(/ /g, '-')}/${region.toLowerCase().replace(/ /g, '-')}` }] : []),
          { label: town, path: `/service-areas/${location.toLowerCase().replace(/ /g, '-')}${location !== region ? `/${region.toLowerCase().replace(/ /g, '-')}` : ''}/${town.toLowerCase().replace(/ /g, '-')}` },
          { label: `${formattedService} Repair`, path: generateServiceLink(service) },
        ]} />
        <h1 className="text-2xl sm:text-4xl font-bold mb-6 sm:mb-8 text-primary-600 border-b pb-4">{content.title}</h1>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 sm:gap-8">
          <div className="lg:col-span-2 space-y-6 sm:space-y-8">
            <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h2 className="text-xl sm:text-2xl font-semibold mb-4 text-primary-600">Professional {formattedService} Repair Services</h2>
              <div className="prose max-w-none text-sm sm:text-base" dangerouslySetInnerHTML={{ __html: content.mainContent }} />
            </div>
            
            <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <WhyChooseUs reasons={content.reasons} />
            </div>
            
            <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <FAQSection faqs={content.faqs} />
            </div>

            <div className="bg-primary-600 p-4 sm:p-6 rounded-lg shadow-md text-white text-center">
              <h3 className="text-xl sm:text-2xl font-bold mb-4">Need {formattedService} Repair in {town}?</h3>
              <CTAButton 
                text={`Schedule Your ${formattedService} Repair`} 
                onClick={openBookingForm} 
                className="bg-white text-primary-600 hover:bg-gray-100 font-bold py-2 px-4 sm:py-3 sm:px-6 rounded-full transition duration-300 shadow-md text-sm sm:text-base"
              />
            </div>
          </div>
          <div className="lg:col-span-1 space-y-6 sm:space-y-8">
            <div className="bg-white p-4 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold mb-4 text-primary-600">Service Area</h3>
              <div style={{ width: '100%', height: '400px' }}>
                <InteractiveMap 
                  center={[townData.lng, townData.lat]}
                  zoom={14}
                  markers={[{ lng: townData.lng, lat: townData.lat, name: town }]}
                />
              </div>
            </div>
            <div className="bg-white p-4 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold mb-4 text-primary-600">Our Services in {town}</h3>
              <ul className="space-y-2 max-h-64 sm:max-h-96 overflow-y-auto pr-2 custom-scrollbar">
                {refrigerationTypes.map((type, index) => (
                  <li key={index}>
                    <Link to={generateServiceLink(type)} className="text-primary-600 hover:text-primary-800 hover:underline text-sm sm:text-base">
                      {type} Repairs in {town}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicePageTemplate;
