import React, { useState, useEffect, useRef } from 'react';
import { FaComments, FaTimes, FaPaperPlane, FaUser } from 'react-icons/fa';

const ChatWidget = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [messages, setMessages] = useState([]);
    const [lastMessageId, setLastMessageId] = useState(0);
    const [newMessage, setNewMessage] = useState('');
    const [userName, setUserName] = useState('');
    const [roomId, setRoomId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showNameInput, setShowNameInput] = useState(true);
    const [unreadCount, setUnreadCount] = useState(0);
    const messagesEndRef = useRef(null);
    const messageContainerRef = useRef(null);
    const pollInterval = useRef(null);
    const [autoScroll, setAutoScroll] = useState(true);

    const scrollToBottom = () => {
        if (autoScroll && messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleScroll = () => {
        if (messageContainerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = messageContainerRef.current;
            const isAtBottom = Math.abs(scrollHeight - clientHeight - scrollTop) < 50;
            setAutoScroll(isAtBottom);
        }
    };

    // Check for active chat
    const checkActiveChat = async () => {
        try {
            const response = await fetch('/api/chat/active', {
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Failed to check active chat');
            }

            const data = await response.json();
            if (data.success && data.hasActiveChat && data.room) {
                setRoomId(data.room.id);
                setUserName(data.room.user_name);
                setShowNameInput(false);
                return true;
            }
            return false;
        } catch (error) {
            console.error('Error checking active chat:', error);
            return false;
        }
    };

    // Check unread messages
    const checkUnreadMessages = async () => {
        if (!roomId) return;
        
        try {
            const response = await fetch(`/api/chat/messages/${roomId}`, {
                credentials: 'include'
            });
            
            if (!response.ok) return;
            
            const data = await response.json();
            if (!data.success) return;

            // Count unread admin messages
            const unreadMessages = data.messages.filter(
                msg => msg.sender_type === 'admin' && !msg.read_at
            ).length;
            setUnreadCount(unreadMessages);
        } catch (error) {
            console.error('Error checking unread messages:', error);
        }
    };

    // Initialize or get existing chat room
    const initializeChat = async (name) => {
        try {
            const response = await fetch('/api/chat/room', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ userName: name })
            });
            
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Failed to initialize chat');
            }
            
            const data = await response.json();
            return data.roomId;
        } catch (error) {
            console.error('Error initializing chat:', error);
            setError('Unable to start chat. Please try again later.');
            return null;
        }
    };

    // Initial fetch of messages
    const fetchInitialMessages = async (chatRoomId) => {
        if (!chatRoomId) return;
        
        try {
            const response = await fetch(`/api/chat/messages/${chatRoomId}`, {
                credentials: 'include'
            });
            
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Failed to fetch messages');
            }
            
            const data = await response.json();
            const sortedMessages = data.messages.reverse();
            setMessages(sortedMessages);
            if (sortedMessages.length > 0) {
                setLastMessageId(sortedMessages[sortedMessages.length - 1].id);
            }
            scrollToBottom();
            setError(null);
        } catch (error) {
            console.error('Error fetching messages:', error);
            setError('Unable to load messages. Please refresh the page.');
        }
    };

    // Fetch new messages only
    const fetchNewMessages = async (chatRoomId) => {
        if (!chatRoomId || !lastMessageId) return;
        
        try {
            const response = await fetch(`/api/chat/messages/${chatRoomId}/new/${lastMessageId}`, {
                credentials: 'include'
            });
            
            if (!response.ok) return;
            
            const data = await response.json();
            if (!data.success || !data.messages.length) return;

            setMessages(prev => [...prev, ...data.messages]);
            setLastMessageId(data.messages[data.messages.length - 1].id);
            scrollToBottom();

            // Update unread count if chat is not expanded
            if (!isExpanded) {
                const unreadMessages = data.messages.filter(
                    msg => msg.sender_type === 'admin' && !msg.read_at
                ).length;
                setUnreadCount(prev => prev + unreadMessages);
            }
        } catch (error) {
            console.error('Error fetching new messages:', error);
        }
    };

    // Handle name submission
    const handleNameSubmit = async (e) => {
        e.preventDefault();
        if (!userName.trim()) return;

        setLoading(true);
        try {
            const currentRoomId = await initializeChat(userName.trim());
            if (currentRoomId) {
                setRoomId(currentRoomId);
                setShowNameInput(false);
                setError(null);
            }
        } catch (error) {
            setError('Failed to start chat. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    // Send message
    const sendMessage = async (e) => {
        e.preventDefault();
        if (!newMessage.trim()) return;

        const originalMessage = newMessage;
        setNewMessage(''); // Clear input immediately for better UX
        setLoading(true);

        try {
            // If no room exists, create one
            const currentRoomId = roomId || await initializeChat(userName);
            if (!currentRoomId) {
                throw new Error('Failed to create chat room');
            }

            // Set roomId if this is the first message
            if (!roomId) {
                setRoomId(currentRoomId);
            }

            // Clear existing polling interval
            if (pollInterval.current) {
                clearInterval(pollInterval.current);
            }

            const response = await fetch('/api/chat/message', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    roomId: currentRoomId,
                    message: originalMessage.trim()
                })
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Failed to send message');
            }

            const data = await response.json();
            if (!data.success) {
                throw new Error(data.error || 'Failed to send message');
            }
            
            // Add the sent message to the messages array immediately
            const newMessageObj = {
                id: Date.now(), // Temporary ID until we fetch the real one
                message: originalMessage.trim(),
                sender_type: 'user',
                created_at: new Date().toISOString(),
                read_at: null
            };
            setMessages(prev => [...prev, newMessageObj]);
            scrollToBottom();
            
            // Restart polling with a fresh interval
            pollInterval.current = setInterval(() => {
                fetchNewMessages(currentRoomId);
                markMessagesAsRead();
            }, 3000);
            
            // Fetch new messages immediately
            await fetchNewMessages(currentRoomId);
            setError(null);
        } catch (error) {
            console.error('Error sending message:', error);
            setError('Failed to send message. Please try again.');
            setNewMessage(originalMessage); // Restore message if failed
        } finally {
            setLoading(false);
        }
    };

    // Mark messages as read
    const markMessagesAsRead = async () => {
        if (!roomId) return;
        
        try {
            const response = await fetch(`/api/chat/messages/read/${roomId}`, {
                method: 'POST',
                credentials: 'include'
            });
            
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Failed to mark messages as read');
            }
        } catch (error) {
            console.error('Error marking messages as read:', error);
        }
    };

    // Check for active chat when widget is opened
    useEffect(() => {
        if (isExpanded) {
            checkActiveChat().then(hasActiveChat => {
                if (hasActiveChat) {
                    fetchInitialMessages(roomId);
                }
            });
        }
    }, [isExpanded]);

    // Setup polling for new messages
    useEffect(() => {
        if (isExpanded && roomId) {
            fetchInitialMessages(roomId);
            markMessagesAsRead();
            setUnreadCount(0); // Reset unread count when expanding
            
            pollInterval.current = setInterval(() => {
                fetchNewMessages(roomId);
                markMessagesAsRead();
            }, 3000); // Poll every 3 seconds for more responsive updates
        }

        return () => {
            if (pollInterval.current) {
                clearInterval(pollInterval.current);
            }
        };
    }, [isExpanded, roomId]);

    // Check for unread messages when not expanded
    useEffect(() => {
        if (!isExpanded && roomId) {
            checkUnreadMessages();
            const interval = setInterval(checkUnreadMessages, 5000);
            return () => clearInterval(interval);
        }
    }, [isExpanded, roomId]);

    // Scroll to bottom when new messages arrive
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    if (!isExpanded) {
        return (
            <div className="fixed bottom-0 right-0 mb-4 mr-4 z-50">
                <button
                    onClick={() => setIsExpanded(true)}
                    className="bg-[#1D4ED8] text-white p-3 sm:p-4 rounded-full shadow-lg hover:bg-blue-700 transition-colors flex items-center gap-2 relative"
                >
                    <FaComments className="text-xl sm:text-2xl" />
                    <span className="hidden sm:inline text-sm font-medium">Chat with us</span>
                    {unreadCount > 0 && (
                        <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs w-5 h-5 flex items-center justify-center rounded-full font-medium">
                            {unreadCount}
                        </span>
                    )}
                </button>
            </div>
        );
    }

    return (
        <div className="fixed inset-0 sm:inset-auto sm:bottom-4 sm:right-4 w-full sm:w-96 h-full sm:h-[500px] bg-white sm:rounded-lg shadow-xl flex flex-col z-50">
            {/* Header */}
            <div className="p-3 sm:p-4 border-b flex justify-between items-center bg-[#1D4ED8] text-white sm:rounded-t-lg">
                <div>
                    <h2 className="font-semibold">Chat with Support</h2>
                    <p className="text-xs text-blue-100">Local Fridge Repair</p>
                </div>
                <button
                    onClick={() => setIsExpanded(false)}
                    className="text-white hover:text-blue-100 transition-colors p-2"
                >
                    <FaTimes className="text-xl" />
                </button>
            </div>

            {error && (
                <div className="p-2 bg-red-50 text-red-600 text-sm border-b border-red-100">
                    {error}
                </div>
            )}

            {/* Name Input or Chat Area */}
            {showNameInput ? (
                <div className="flex-1 flex flex-col items-center justify-center p-4 sm:p-8 bg-gray-50">
                    <div className="w-full max-w-sm">
                        <h3 className="text-lg font-medium text-gray-900 mb-2 text-center">
                            Welcome to Local Fridge Repair
                        </h3>
                        <p className="text-sm text-gray-600 mb-6 text-center">
                            Enter your name to start chatting with our support team
                        </p>
                        <form onSubmit={handleNameSubmit} className="space-y-4">
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <FaUser className="text-gray-400" />
                                </div>
                                <input
                                    type="text"
                                    value={userName}
                                    onChange={(e) => setUserName(e.target.value)}
                                    placeholder="Your name"
                                    className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:border-[#1D4ED8] focus:ring-1 focus:ring-[#1D4ED8] transition-colors"
                                    disabled={loading}
                                />
                            </div>
                            <button
                                type="submit"
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#1D4ED8] hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1D4ED8] disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                                disabled={!userName.trim() || loading}
                            >
                                {loading ? 'Starting chat...' : 'Start Chat'}
                            </button>
                        </form>
                    </div>
                </div>
            ) : (
                <>
                    {/* Chat Area */}
                    <div 
                        ref={messageContainerRef}
                        onScroll={handleScroll}
                        className="flex-1 overflow-y-auto p-3 sm:p-4 space-y-3 sm:space-y-4 bg-gray-50"
                    >
                        {loading && messages.length === 0 ? (
                            <div className="flex justify-center items-center h-full">
                                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#1D4ED8]"></div>
                            </div>
                        ) : (
                            <>
                                {messages.length === 0 ? (
                                    <div className="text-center text-gray-500 mt-4">
                                        Send a message to start a conversation with our support team!
                                    </div>
                                ) : (
                                    messages.map(message => (
                                        <div
                                            key={message.id}
                                            className={`flex flex-col max-w-[85%] sm:max-w-[75%] ${
                                                message.sender_type === 'user' ? 'ml-auto' : 'mr-auto'
                                            }`}
                                        >
                                            <div
                                                className={`rounded-lg px-3 sm:px-4 py-2 shadow-sm ${
                                                    message.sender_type === 'user'
                                                        ? 'bg-[#1D4ED8] text-white'
                                                        : 'bg-white border border-gray-200 text-gray-800'
                                                }`}
                                            >
                                                <p className="text-sm break-words">{message.message}</p>
                                            </div>
                                            <span className={`text-xs mt-1 ${
                                                message.sender_type === 'user' ? 'text-right text-gray-500' : 'text-left text-gray-500'
                                            }`}>
                                                {new Date(message.created_at).toLocaleTimeString()}
                                            </span>
                                        </div>
                                    ))
                                )}
                                <div ref={messagesEndRef} />
                            </>
                        )}
                    </div>

                    {/* Input Area */}
                    <form onSubmit={sendMessage} className="p-3 sm:p-4 border-t bg-white">
                        <div className="flex gap-2">
                            <input
                                type="text"
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                placeholder="Type a message..."
                                className="flex-1 p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-[#1D4ED8] focus:ring-1 focus:ring-[#1D4ED8] transition-colors"
                                disabled={loading}
                            />
                            <button
                                type="submit"
                                className="bg-[#1D4ED8] text-white p-2 rounded-lg hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                                disabled={!newMessage.trim() || loading}
                            >
                                <FaPaperPlane />
                            </button>
                        </div>
                    </form>
                </>
            )}
        </div>
    );
};

export default ChatWidget;
