import React from 'react';
import { InputWithIcon, SelectWithIcon } from './FormComponents';

const fridgeTypes = [
  { value: 'Walk-in Cooler', label: 'Walk-in Cooler' },
  { value: 'Walk-in Freezer', label: 'Walk-in Freezer' },
  { value: 'Reach-in Cooler', label: 'Reach-in Cooler' },
  { value: 'Reach-in Freezer', label: 'Reach-in Freezer' },
  { value: 'Under Counter Fridge', label: 'Under Counter Fridge' },
  { value: 'Display Case', label: 'Display Case' },
  { value: 'Ice Machine', label: 'Ice Machine' },
  { value: 'Other', label: 'Other' },
];

const fridgeBrands = [
  { value: 'Foster', label: 'Foster' },
  { value: 'Williams', label: 'Williams' },
  { value: 'Polar', label: 'Polar' },
  { value: 'True', label: 'True' },
  { value: 'Gram', label: 'Gram' },
  { value: 'Electrolux', label: 'Electrolux' },
  { value: 'Liebherr', label: 'Liebherr' },
  { value: 'Other', label: 'Other' },
];

const InstallationForm = ({ formData, handleInputChange, errors }) => {
  return (
    <>
      <SelectWithIcon
        icon="fa-snowflake"
        name="fridgeType"
        value={formData.fridgeType}
        onChange={handleInputChange}
        options={fridgeTypes}
        required
        label="Fridge Type"
        error={errors.fridgeType}
      />
      <SelectWithIcon
        icon="fa-industry"
        name="fridgeBrand"
        value={formData.fridgeBrand}
        onChange={handleInputChange}
        options={fridgeBrands}
        required
        label="Fridge Brand"
        error={errors.fridgeBrand}
      />
      <InputWithIcon
        icon="fa-ruler-combined"
        type="text"
        name="installationSpace"
        value={formData.installationSpace}
        onChange={handleInputChange}
        placeholder="Describe the installation space"
        required
        label="Installation Space"
        error={errors.installationSpace}
      />
      <InputWithIcon
        icon="fa-clipboard"
        type="textarea"
        name="installationNotes"
        value={formData.installationNotes}
        onChange={handleInputChange}
        placeholder="Any additional notes for installation"
        label="Installation Notes"
        error={errors.installationNotes}
      />
    </>
  );
};

export default InstallationForm;