const refrigerationTypes = [
  'Bottle Cooler', 'Walk-in Fridge', 'Walk-in Freezer', 'Upright Freezer', 'Upright Fridge',
  'Chest Freezer', 'Display Refrigerator', 'Display Freezer', 'Refrigerated Prep Table', 
  'Undercounter Refrigerator', 'Undercounter Freezer', 'Blast Chiller', 'Beverage Cooler', 
  'Wine Cooler', 'Deli Case', 'Serve Over Counter', 'Bar Cooler', 'Salad Bar', 
  'Bakery Display Refrigerator', 'Merchandising Refrigerator', 'Pharmacy Refrigerator',
  'Medical and Laboratory Refrigerator', 'Ice Cream Freezer', 'Gelato Fridge',
  'Supermarket Refrigeration System', 'Convenience Store Refrigerator', 'Underbar Refrigerator',
  'Keg Cooler and Cellar Cooler', 'Rack Refrigeration System', 'Cold Room', 'Ice Machine',
  'Glass Door Refrigerator', 'Back Bar Cooler', 'Milk Cooler', 'Pizza Prep Table',
  'Glycol Beer Chiller', 'Open Air Merchandiser (Grab-and-Go Cooler)', 'Island Freezer',
  'Fish and Seafood Refrigerated Display', 'Multideck Refrigerator',
  'Frozen Yogurt and Soft Serve Machine Freezer', 'Portable Refrigeration Unit'
];

module.exports = refrigerationTypes;