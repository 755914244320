import React, { useState, useEffect, useRef } from 'react';
import { format } from 'date-fns';
import { Link, useNavigate } from 'react-router-dom';
import { FaSearch, FaBell, FaComments } from 'react-icons/fa';

function NotificationSystem({ lastRefresh }) {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const pollInterval = useRef(null);

  useEffect(() => {
    // Initial fetch
    fetchNotifications();

    // Setup polling
    pollInterval.current = setInterval(() => {
      fetchNotifications();
    }, 3000); // Poll every 3 seconds

    return () => {
      if (pollInterval.current) {
        clearInterval(pollInterval.current);
      }
    };
  }, [lastRefresh]);

  const handleAuthError = () => {
    localStorage.removeItem('auth_token');
    navigate('/admin/login');
  };

  const getAuthHeaders = () => {
    const token = localStorage.getItem('auth_token');
    return {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
  };

  const fetchNotifications = async () => {
    try {
      const token = localStorage.getItem('auth_token');
      if (!token) {
        handleAuthError();
        return;
      }

      const [notificationsResponse, chatResponse] = await Promise.all([
        fetch('/api/admin/notifications', {
          credentials: 'include',
          headers: getAuthHeaders()
        }),
        fetch('/api/chat/rooms', {
          headers: getAuthHeaders()
        })
      ]);

      if (!notificationsResponse.ok || !chatResponse.ok) {
        if (notificationsResponse.status === 401 || notificationsResponse.status === 403 ||
            chatResponse.status === 401 || chatResponse.status === 403) {
          handleAuthError();
          return;
        }
        throw new Error('Failed to fetch notifications');
      }

      const [notificationsData, chatData] = await Promise.all([
        notificationsResponse.json(),
        chatResponse.json()
      ]);
      
      // Transform notifications
      const transformedNotifications = notificationsData.notifications.map(notification => ({
        ...notification,
        read: notification.read_at !== null,
        type: notification.type || 'general'
      }));

      // Transform chat messages into notifications
      const chatNotifications = chatData.rooms
        .filter(room => room.last_message && room.unread_count > 0)
        .map(room => ({
          id: `chat-${room.id}-${room.updated_at}`,
          type: 'chat_message',
          message: `New message from ${room.user_name}: ${room.last_message}`,
          created_at: room.updated_at,
          read: false,
          data: {
            roomId: room.id,
            userName: room.user_name
          }
        }));

      // Combine and sort all notifications by date
      const allNotifications = [...transformedNotifications, ...chatNotifications]
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      
      setNotifications(allNotifications);
      setError(null);
      setUnreadCount(allNotifications.filter(n => !n.read).length);
    } catch (err) {
      console.error('Error fetching notifications:', err);
      setError(err.message);
    }
  };

  const markAsRead = async (id, event) => {
    event.stopPropagation();
    try {
      // If it's a chat notification, don't try to mark it as read here
      if (id.startsWith('chat-')) {
        return;
      }

      const response = await fetch(`/api/admin/notifications/${id}/read`, {
        method: 'POST',
        credentials: 'include',
        headers: getAuthHeaders()
      });

      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          handleAuthError();
          return;
        }
        throw new Error('Failed to mark notification as read');
      }
      
      setNotifications(notifications.map(notification => 
        notification.id === id ? { ...notification, read: true, read_at: new Date().toISOString() } : notification
      ));
      setUnreadCount(Math.max(0, unreadCount - 1));
    } catch (error) {
      console.error('Error marking notification as read:', error);
      setError(error.message);
    }
  };

  const markAllAsRead = async (event) => {
    event.stopPropagation();
    try {
      const response = await fetch('/api/admin/notifications/read-all', {
        method: 'POST',
        credentials: 'include',
        headers: getAuthHeaders()
      });

      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          handleAuthError();
          return;
        }
        throw new Error('Failed to mark all notifications as read');
      }
      
      const now = new Date().toISOString();
      setNotifications(notifications.map(notification => {
        // Don't mark chat notifications as read
        if (notification.id.startsWith('chat-')) {
          return notification;
        }
        return { 
          ...notification, 
          read: true,
          read_at: notification.read_at || now
        };
      }));
      setUnreadCount(notifications.filter(n => n.id.startsWith('chat-')).length);
    } catch (error) {
      console.error('Error marking all as read:', error);
      setError(error.message);
    }
  };

  const deleteNotification = async (id, event) => {
    event.stopPropagation();
    try {
      // If it's a chat notification, just remove it from the state
      if (id.startsWith('chat-')) {
        setNotifications(notifications.filter(n => n.id !== id));
        if (!notifications.find(n => n.id === id)?.read) {
          setUnreadCount(Math.max(0, unreadCount - 1));
        }
        return;
      }

      const response = await fetch(`/api/admin/notifications/${id}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: getAuthHeaders()
      });

      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          handleAuthError();
          return;
        }
        throw new Error('Failed to delete notification');
      }
      
      const notificationToDelete = notifications.find(n => n.id === id);
      setNotifications(notifications.filter(notification => notification.id !== id));
      if (notificationToDelete && !notificationToDelete.read) {
        setUnreadCount(Math.max(0, unreadCount - 1));
      }
    } catch (error) {
      console.error('Error deleting notification:', error);
      setError(error.message);
    }
  };

  const handleNotificationClick = (notification) => {
    if (notification.type === 'chat_message') {
      navigate('/admin/chat');
    } else if (notification.data?.id) {
      navigate(`/admin/bookings/${notification.data.id}`);
    }
  };

  const getNotificationIcon = (type) => {
    switch (type) {
      case 'chat_message':
        return (
          <FaComments className="h-6 w-6 text-blue-500" />
        );
      case 'new_booking':
        return (
          <svg className="h-6 w-6 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
        );
      case 'booking_status_change':
        return (
          <svg className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        );
      default:
        return (
          <svg className="h-6 w-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        );
    }
  };

  // Filter notifications based on search term
  const filteredNotifications = notifications.filter(notification =>
    notification.message.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="h-full bg-white flex flex-col">
      {/* Header */}
      <div className="p-4 border-b border-gray-200 bg-white">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center">
            <FaBell className="h-6 w-6 text-gray-500 mr-2" />
            <h2 className="text-lg font-semibold text-gray-900">Notifications</h2>
            {unreadCount > 0 && (
              <span className="ml-2 bg-red-500 text-white text-xs px-2 py-1 rounded-full">
                {unreadCount}
              </span>
            )}
          </div>
          {notifications.some(n => !n.read && !n.id.startsWith('chat-')) && (
            <button
              onClick={markAllAsRead}
              className="text-sm text-blue-600 hover:text-blue-800"
            >
              Mark all as read
            </button>
          )}
        </div>
        <div className="relative">
          <FaSearch className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Search notifications..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 bg-gray-50 text-gray-900 rounded-lg border border-gray-200 focus:outline-none focus:border-blue-500"
          />
        </div>
      </div>

      {/* Notifications List */}
      <div className="flex-1 overflow-y-auto">
        {loading && notifications.length === 0 ? (
          <div className="flex justify-center items-center h-full">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
          </div>
        ) : error ? (
          <div className="p-4 text-red-600">{error}</div>
        ) : filteredNotifications.length === 0 ? (
          <div className="p-4 text-center text-gray-500">No notifications</div>
        ) : (
          <div className="divide-y divide-gray-200">
            {filteredNotifications.map((notification) => (
              <div
                key={notification.id}
                onClick={() => handleNotificationClick(notification)}
                className={`p-4 ${notification.read ? 'bg-white' : 'bg-blue-50'} cursor-pointer hover:bg-gray-50`}
              >
                <div className="flex items-start space-x-4">
                  <div className="flex-shrink-0">
                    {getNotificationIcon(notification.type)}
                  </div>
                  <div className="flex-1 min-w-0">
                    <p className="text-sm text-gray-900">{notification.message}</p>
                    {notification.data?.serviceM8JobId && (
                      <p className="mt-1 text-sm font-medium text-blue-600">
                        Job ID: {notification.data.serviceM8JobId}
                      </p>
                    )}
                    <p className="mt-1 text-xs text-gray-500">
                      {format(new Date(notification.created_at), 'dd/MM/yyyy HH:mm')}
                    </p>
                  </div>
                  <div className="flex-shrink-0 flex space-x-2">
                    {!notification.read && !notification.id.startsWith('chat-') && (
                      <button
                        onClick={(e) => markAsRead(notification.id, e)}
                        className="text-blue-600 hover:text-blue-800"
                      >
                        <span className="sr-only">Mark as read</span>
                        <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                      </button>
                    )}
                    <button
                      onClick={(e) => deleteNotification(notification.id, e)}
                      className="text-gray-400 hover:text-gray-600"
                    >
                      <span className="sr-only">Delete</span>
                      <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default NotificationSystem;
