import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import StaffSelector from './StaffSelector';

function BookingDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [booking, setBooking] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedStaff, setSelectedStaff] = useState('');
  const [updating, setUpdating] = useState(false);
  const [updateError, setUpdateError] = useState(null);
  const isSubmitting = useRef(false);
  const abortController = useRef(null);

  const handleAuthError = () => {
    localStorage.removeItem('auth_token');
    navigate('/admin/login');
  };

  const getAuthHeaders = () => {
    const token = localStorage.getItem('auth_token');
    return {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
  };

  const fetchJobDetails = async (jobUuid) => {
    try {
      const token = localStorage.getItem('auth_token');
      if (!token) {
        handleAuthError();
        return;
      }

      const response = await fetch(`/api/admin/bookings/${id}/job-details`, {
        method: 'POST',
        headers: getAuthHeaders(),
        credentials: 'include',
        body: JSON.stringify({ jobUuid })
      });

      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          handleAuthError();
          return;
        }
        throw new Error('Failed to fetch job details');
      }

      const result = await response.json();

      if (result.success && result.jobId) {
        await fetchBooking();
      }
    } catch (err) {
      console.error('Error fetching job details:', err);
      setError(err.message);
    }
  };

  const fetchBooking = async () => {
    try {
      const token = localStorage.getItem('auth_token');
      if (!token) {
        handleAuthError();
        return;
      }

      if (abortController.current) {
        abortController.current.abort();
      }
      abortController.current = new AbortController();

      const response = await fetch(`/api/admin/bookings/${id}`, {
        credentials: 'include',
        headers: getAuthHeaders(),
        signal: abortController.current.signal
      });
      
      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          handleAuthError();
          return;
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      setBooking(data);
      setError(null);

      if (data.servicem8_job_uuid && !data.servicem8_job_id) {
        await fetchJobDetails(data.servicem8_job_uuid);
      }
    } catch (err) {
      if (err.name === 'AbortError') {
        return;
      }
      console.error('Error fetching booking:', err);
      setError(err.message);
    } finally {
      setLoading(false);
      abortController.current = null;
    }
  };

  useEffect(() => {
    fetchBooking();

    return () => {
      if (abortController.current) {
        abortController.current.abort();
      }
    };
  }, [id]);

  const handleStatusUpdate = async (newStatus) => {
    if (isSubmitting.current) {
      console.log('Submission already in progress');
      return;
    }

    if (newStatus === 'accepted' && !selectedStaff) {
      setUpdateError('Please select a staff member before accepting the booking');
      return;
    }

    try {
      const token = localStorage.getItem('auth_token');
      if (!token) {
        handleAuthError();
        return;
      }

      isSubmitting.current = true;
      setUpdating(true);
      setUpdateError(null);

      const response = await fetch(`/api/admin/bookings/${id}/status`, {
        method: 'POST',
        headers: getAuthHeaders(),
        credentials: 'include',
        body: JSON.stringify({
          status: newStatus,
          staffUuid: selectedStaff
        })
      });

      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          handleAuthError();
          return;
        }
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update booking status');
      }

      await fetchBooking();
      setUpdateError(null);
    } catch (err) {
      console.error('Error updating booking status:', err);
      setUpdateError(err.message);
    } finally {
      setUpdating(false);
      isSubmitting.current = false;
    }
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'pending':
        return 'bg-yellow-100 text-yellow-800 border-yellow-200';
      case 'accepted':
        return 'bg-green-100 text-green-800 border-green-200';
      case 'rejected':
        return 'bg-red-100 text-red-800 border-red-200';
      default:
        return 'bg-gray-100 text-gray-800 border-gray-200';
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 bg-red-50 border border-red-200 text-red-700">
        Error: {error}
      </div>
    );
  }

  if (!booking) {
    return (
      <div className="p-4 bg-yellow-50 border border-yellow-200 text-yellow-700">
        Booking not found
      </div>
    );
  }

  return (
    <div className="h-full flex flex-col">
      <div className="p-6 border-b border-gray-200 bg-white">
        <div className="flex justify-between items-start">
          <div>
            <h2 className="text-2xl font-bold text-gray-800">Booking Details</h2>
            <p className="text-sm text-gray-500">
              Created on {format(new Date(booking.created_at), 'dd/MM/yyyy HH:mm')}
            </p>
          </div>
          <button
            onClick={() => navigate('/admin/bookings')}
            className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800"
          >
            ← Back to Bookings
          </button>
        </div>
      </div>

      <div className="flex-1 overflow-auto p-6">
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <div className="p-6 border-b">
            <div className="flex justify-between items-start">
              <div>
                <h3 className="text-lg font-semibold text-gray-800">
                  {booking.name}
                  {booking.business_name && (
                    <span className="ml-2 text-sm text-gray-500">
                      ({booking.business_name})
                    </span>
                  )}
                </h3>
                <div className="mt-1 text-sm text-gray-600">
                  {booking.email} • {booking.phone}
                </div>
              </div>
              <div className="flex flex-col items-end">
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium border ${getStatusBadgeClass(booking.status)}`}>
                  {booking.status.charAt(0).toUpperCase() + booking.status.slice(1)}
                </span>
                {booking.urgent && (
                  <span className="mt-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                    Urgent
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="p-6 grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <h4 className="font-medium text-gray-800 mb-2">Service Details</h4>
              <dl className="space-y-2">
                <div>
                  <dt className="text-sm text-gray-500">Service Type</dt>
                  <dd className="text-sm text-gray-800">
                    {booking.service_type.charAt(0).toUpperCase() + booking.service_type.slice(1)}
                  </dd>
                </div>
                <div>
                  <dt className="text-sm text-gray-500">Fridge Type</dt>
                  <dd className="text-sm text-gray-800">{booking.fridge_type}</dd>
                </div>
                {booking.fridge_brand && (
                  <div>
                    <dt className="text-sm text-gray-500">Fridge Brand</dt>
                    <dd className="text-sm text-gray-800">{booking.fridge_brand}</dd>
                  </div>
                )}
                {booking.service_date && (
                  <div>
                    <dt className="text-sm text-gray-500">Service Date</dt>
                    <dd className="text-sm text-gray-800">
                      {format(new Date(booking.service_date), 'dd/MM/yyyy')}
                    </dd>
                  </div>
                )}
              </dl>
            </div>

            <div>
              <h4 className="font-medium text-gray-800 mb-2">Location</h4>
              <dl className="space-y-2">
                <div>
                  <dt className="text-sm text-gray-500">Building Name/Number</dt>
                  <dd className="text-sm text-gray-800">{booking.building_name_number}</dd>
                </div>
                <div>
                  <dt className="text-sm text-gray-500">Town</dt>
                  <dd className="text-sm text-gray-800">{booking.town}</dd>
                </div>
                <div>
                  <dt className="text-sm text-gray-500">Postcode</dt>
                  <dd className="text-sm text-gray-800">{booking.postcode}</dd>
                </div>
              </dl>
            </div>
          </div>

          {booking.servicem8_job_uuid && (
            <div className="px-6 py-4 bg-gray-50 border-t">
              <h4 className="font-medium text-gray-800 mb-2">ServiceM8 Details</h4>
              <dl className="space-y-2">
                {booking.servicem8_job_id ? (
                  <div>
                    <dt className="text-sm text-gray-500">Job ID</dt>
                    <dd className="text-sm font-medium text-blue-600">
                      {booking.servicem8_job_id}
                    </dd>
                  </div>
                ) : (
                  <div className="flex items-center">
                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-blue-600 mr-2"></div>
                    <span className="text-sm text-gray-600">Fetching job ID...</span>
                  </div>
                )}
              </dl>
            </div>
          )}

          {booking.status === 'pending' && (
            <div className="p-6 bg-gray-50 border-t">
              <h4 className="font-medium text-gray-800 mb-4">Actions</h4>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Assign Staff Member
                  </label>
                  <StaffSelector
                    value={selectedStaff}
                    onChange={setSelectedStaff}
                    className="w-full"
                  />
                </div>
                {updateError && (
                  <div className="p-3 bg-red-50 border border-red-200 rounded-lg text-sm text-red-700">
                    {updateError}
                  </div>
                )}
                <div className="flex gap-4">
                  <button
                    onClick={() => handleStatusUpdate('accepted')}
                    disabled={updating || isSubmitting.current}
                    className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {updating ? 'Processing...' : 'Accept Booking'}
                  </button>
                  <button
                    onClick={() => handleStatusUpdate('rejected')}
                    disabled={updating || isSubmitting.current}
                    className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {updating ? 'Processing...' : 'Reject Booking'}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BookingDetail;
