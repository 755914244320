import React, { useState, useEffect, useRef } from 'react';
import { FaSearch, FaUser, FaChevronLeft, FaChevronRight, FaPaperPlane, FaComments } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import ChatMessage from './ChatMessage';

const QuickResponse = ({ text, onClick }) => (
    <button
        onClick={() => onClick(text)}
        className="px-3 py-1.5 text-sm bg-gray-100 text-gray-700 rounded-full hover:bg-gray-200 transition-colors whitespace-nowrap"
    >
        {text}
    </button>
);

const ChatPanel = () => {
    const navigate = useNavigate();
    const [activeRooms, setActiveRooms] = useState([]);
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [messages, setMessages] = useState([]);
    const [lastMessageId, setLastMessageId] = useState(0);
    const [newMessage, setNewMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [showUserInfo, setShowUserInfo] = useState(false);
    const [showSidebar, setShowSidebar] = useState(true);
    const messagesEndRef = useRef(null);
    const messageContainerRef = useRef(null);
    const pollInterval = useRef(null);
    const [autoScroll, setAutoScroll] = useState(true);

    // Quick responses
    const quickResponses = [
        "Hi, how can I help you today?",
        "I'll check that for you right away.",
        "Could you provide more details?",
        "We'll get back to you shortly.",
        "Is there anything else you need help with?"
    ];

    const scrollToBottom = () => {
        if (autoScroll && messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleScroll = () => {
        if (messageContainerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = messageContainerRef.current;
            const isAtBottom = Math.abs(scrollHeight - clientHeight - scrollTop) < 50;
            setAutoScroll(isAtBottom);
        }
    };

    const getAuthHeaders = () => ({
        'Authorization': `Bearer ${localStorage.getItem('auth_token')}`,
        'Content-Type': 'application/json'
    });

    const handleAuthError = () => {
        localStorage.removeItem('auth_token');
        navigate('/admin/login');
    };

    // Handle quick response click
    const handleQuickResponse = (text) => {
        setNewMessage(text);
    };

    // Filter rooms based on search
    const filteredRooms = activeRooms.filter(room => 
        room.user_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        room.last_message?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Fetch active chat rooms
    const fetchActiveRooms = async () => {
        try {
            const token = localStorage.getItem('auth_token');
            if (!token) {
                handleAuthError();
                return;
            }

            const response = await fetch('/api/chat/rooms', {
                headers: getAuthHeaders()
            });

            if (!response.ok) {
                if (response.status === 401) {
                    handleAuthError();
                    return;
                }
                throw new Error('Failed to fetch rooms');
            }

            const data = await response.json();
            if (!data.success) {
                throw new Error(data.error || 'Failed to fetch rooms');
            }

            setActiveRooms(data.rooms);
            setError(null);
        } catch (error) {
            console.error('Error fetching rooms:', error);
            setError('Failed to load chat rooms');
        }
    };

    // Initial fetch of messages
    const fetchInitialMessages = async (roomId) => {
        try {
            setLoading(true);
            const token = localStorage.getItem('auth_token');
            if (!token) {
                handleAuthError();
                return;
            }

            const response = await fetch(`/api/chat/messages/${roomId}`, {
                headers: getAuthHeaders()
            });

            if (!response.ok) {
                if (response.status === 401) {
                    handleAuthError();
                    return;
                }
                throw new Error('Failed to fetch messages');
            }

            const data = await response.json();
            if (!data.success) {
                throw new Error(data.error || 'Failed to fetch messages');
            }

            const sortedMessages = data.messages.reverse();
            setMessages(sortedMessages);
            if (sortedMessages.length > 0) {
                setLastMessageId(sortedMessages[sortedMessages.length - 1].id);
            }
            scrollToBottom();
            setError(null);
        } catch (error) {
            console.error('Error fetching messages:', error);
            setError('Failed to load messages');
        } finally {
            setLoading(false);
        }
    };

    // Fetch new messages only
    const fetchNewMessages = async (roomId) => {
        if (!lastMessageId) return;

        try {
            const token = localStorage.getItem('auth_token');
            if (!token) {
                handleAuthError();
                return;
            }

            const response = await fetch(`/api/chat/messages/${roomId}/new/${lastMessageId}`, {
                headers: getAuthHeaders()
            });

            if (!response.ok) {
                if (response.status === 401) {
                    handleAuthError();
                    return;
                }
                return;
            }

            const data = await response.json();
            if (!data.success || !data.messages.length) return;

            setMessages(prev => [...prev, ...data.messages]);
            setLastMessageId(data.messages[data.messages.length - 1].id);
            scrollToBottom();
        } catch (error) {
            console.error('Error fetching new messages:', error);
        }
    };

    // Send message
    const sendMessage = async (e) => {
        e.preventDefault();
        if (!newMessage.trim() || !selectedRoom) return;

        const originalMessage = newMessage;
        setNewMessage(''); // Clear input immediately for better UX

        try {
            const token = localStorage.getItem('auth_token');
            if (!token) {
                handleAuthError();
                return;
            }

            const response = await fetch('/api/chat/message', {
                method: 'POST',
                headers: getAuthHeaders(),
                body: JSON.stringify({
                    roomId: selectedRoom,
                    message: originalMessage.trim()
                })
            });

            if (!response.ok) {
                if (response.status === 401) {
                    handleAuthError();
                    return;
                }
                throw new Error('Failed to send message');
            }

            const data = await response.json();
            if (!data.success) {
                throw new Error(data.error || 'Failed to send message');
            }

            setError(null);
            setAutoScroll(true); // Enable auto-scroll when sending a new message
            await fetchNewMessages(selectedRoom);
        } catch (error) {
            console.error('Error sending message:', error);
            setError('Failed to send message');
            setNewMessage(originalMessage); // Restore message if failed
        }
    };

    // Mark messages as read
    const markMessagesAsRead = async (roomId) => {
        try {
            const token = localStorage.getItem('auth_token');
            if (!token) {
                handleAuthError();
                return;
            }

            const response = await fetch(`/api/chat/messages/read/${roomId}`, {
                method: 'POST',
                headers: getAuthHeaders()
            });

            if (!response.ok && response.status === 401) {
                handleAuthError();
                return;
            }
        } catch (error) {
            console.error('Error marking messages as read:', error);
        }
    };

    // Close chat room
    const closeRoom = async (roomId) => {
        try {
            const token = localStorage.getItem('auth_token');
            if (!token) {
                handleAuthError();
                return;
            }

            const response = await fetch(`/api/chat/room/close/${roomId}`, {
                method: 'POST',
                headers: getAuthHeaders()
            });

            if (!response.ok) {
                if (response.status === 401) {
                    handleAuthError();
                    return;
                }
                throw new Error('Failed to close room');
            }

            const data = await response.json();
            if (!data.success) {
                throw new Error(data.error || 'Failed to close room');
            }

            setSelectedRoom(null);
            await fetchActiveRooms();
            setError(null);
        } catch (error) {
            console.error('Error closing room:', error);
            setError('Failed to close chat room');
        }
    };

    // Setup polling for new messages and rooms
    useEffect(() => {
        fetchActiveRooms();
        pollInterval.current = setInterval(() => {
            fetchActiveRooms();
            if (selectedRoom) {
                fetchNewMessages(selectedRoom);
            }
        }, 3000); // Poll every 3 seconds for more responsive updates

        return () => {
            if (pollInterval.current) {
                clearInterval(pollInterval.current);
            }
        };
    }, [selectedRoom, lastMessageId]);

    // Fetch messages when room is selected
    useEffect(() => {
        if (selectedRoom) {
            fetchInitialMessages(selectedRoom);
            markMessagesAsRead(selectedRoom);
        } else {
            setMessages([]);
            setLastMessageId(0);
        }
    }, [selectedRoom]);

    // Scroll to bottom when new messages arrive
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const selectedRoomData = activeRooms.find(room => room.id === selectedRoom);

    return (
        <div className="h-full bg-white">
            <div className="flex h-full">
                {/* Sidebar */}
                <div className={`${showSidebar ? 'w-80' : 'w-0'} lg:w-80 border-r border-gray-200 flex flex-col transition-all duration-300 overflow-hidden`}>
                    {/* Search */}
                    <div className="p-4 border-b border-gray-200">
                        <div className="relative">
                            <FaSearch className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
                            <input
                                type="text"
                                placeholder="Search chats..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="w-full pl-10 pr-4 py-2 bg-gray-50 text-gray-900 rounded-lg border border-gray-200 focus:outline-none focus:border-blue-500"
                            />
                        </div>
                    </div>

                    {/* Chat List */}
                    <div className="flex-1 overflow-y-auto">
                        {filteredRooms.map(room => (
                            <div
                                key={room.id}
                                onClick={() => setSelectedRoom(room.id)}
                                className={`p-4 cursor-pointer hover:bg-gray-50 border-b border-gray-200 transition-colors ${
                                    selectedRoom === room.id ? 'bg-gray-50' : ''
                                }`}
                            >
                                <div className="flex items-start justify-between">
                                    <div className="flex-1">
                                        <div className="flex items-center justify-between">
                                            <h3 className="font-medium text-gray-900">{room.user_name}</h3>
                                            <span className="text-xs text-gray-500">
                                                {new Date(room.updated_at).toLocaleTimeString()}
                                            </span>
                                        </div>
                                        <p className="text-sm text-gray-600 truncate mt-1">
                                            {room.last_message || 'No messages'}
                                        </p>
                                    </div>
                                    {room.unread_count > 0 && (
                                        <span className="ml-2 bg-blue-500 text-white text-xs px-2 py-1 rounded-full">
                                            {room.unread_count}
                                        </span>
                                    )}
                                </div>
                            </div>
                        ))}
                        {filteredRooms.length === 0 && (
                            <div className="p-4 text-center text-gray-500">
                                No chats found
                            </div>
                        )}
                    </div>
                </div>

                {/* Main Chat Area */}
                <div className="flex-1 flex flex-col min-w-0">
                    {selectedRoom ? (
                        <>
                            {/* Chat Header */}
                            <div className="p-4 border-b border-gray-200 bg-white flex items-center justify-between">
                                <div>
                                    <h2 className="font-semibold text-gray-900">
                                        {selectedRoomData?.user_name}
                                    </h2>
                                    <p className="text-xs text-gray-500">Active conversation</p>
                                </div>
                                <div className="flex items-center gap-4">
                                    <button
                                        onClick={() => setShowUserInfo(!showUserInfo)}
                                        className="text-gray-500 hover:text-gray-700 transition-colors"
                                    >
                                        <FaUser />
                                    </button>
                                    <button
                                        onClick={() => closeRoom(selectedRoom)}
                                        className="text-red-500 hover:text-red-700 transition-colors"
                                    >
                                        Close Chat
                                    </button>
                                </div>
                            </div>

                            {/* Messages */}
                            <div className="flex-1 flex">
                                <div className="flex-1 flex flex-col min-w-0">
                                    <div 
                                        ref={messageContainerRef}
                                        onScroll={handleScroll}
                                        className="flex-1 overflow-y-auto p-4 space-y-4 bg-gray-50"
                                    >
                                        {loading && messages.length === 0 ? (
                                            <div className="flex justify-center items-center h-full">
                                                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                                            </div>
                                        ) : (
                                            <>
                                                {messages.map(message => (
                                                    <ChatMessage
                                                        key={message.id}
                                                        message={message}
                                                    />
                                                ))}
                                                <div ref={messagesEndRef} />
                                            </>
                                        )}
                                    </div>

                                    {/* Quick Responses */}
                                    <div className="p-3 border-t border-gray-200 bg-white flex gap-2 overflow-x-auto">
                                        {quickResponses.map((response, index) => (
                                            <QuickResponse
                                                key={index}
                                                text={response}
                                                onClick={handleQuickResponse}
                                            />
                                        ))}
                                    </div>

                                    {/* Input Area */}
                                    <form onSubmit={sendMessage} className="p-4 border-t border-gray-200 bg-white">
                                        <div className="flex gap-2">
                                            <input
                                                type="text"
                                                value={newMessage}
                                                onChange={(e) => setNewMessage(e.target.value)}
                                                placeholder="Type a message..."
                                                className="flex-1 p-2 bg-gray-50 border border-gray-200 rounded-lg text-gray-900 placeholder-gray-500 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors"
                                                disabled={loading}
                                            />
                                            <button
                                                type="submit"
                                                className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                                                disabled={!newMessage.trim() || loading}
                                            >
                                                <FaPaperPlane />
                                            </button>
                                        </div>
                                    </form>
                                </div>

                                {/* User Info Sidebar */}
                                {showUserInfo && selectedRoomData && (
                                    <div className="w-64 border-l border-gray-200 bg-white p-4">
                                        <h3 className="font-medium text-gray-900 mb-4">User Information</h3>
                                        <div className="space-y-4">
                                            <div>
                                                <label className="text-xs text-gray-500">Name</label>
                                                <p className="text-gray-900">{selectedRoomData.user_name}</p>
                                            </div>
                                            <div>
                                                <label className="text-xs text-gray-500">Chat Started</label>
                                                <p className="text-gray-900">
                                                    {new Date(selectedRoomData.created_at).toLocaleString()}
                                                </p>
                                            </div>
                                            <div>
                                                <label className="text-xs text-gray-500">Status</label>
                                                <p className="text-green-500">Active</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    ) : (
                        <div className="flex-1 flex items-center justify-center text-gray-500 bg-gray-50">
                            <div className="text-center">
                                <FaComments className="text-4xl mb-2 mx-auto" />
                                <p>Select a chat to start messaging</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ChatPanel;
