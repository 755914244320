const refrigerationTypes = [
  "Commercial Fridge",
  "Commercial Freezer",
  "Walk-in Chiller",
  "Walk-in Freezer",
  "Display Fridge",
  "Ice Machine",
  "Ice Cream Machine",
  "Blast Chiller",
  "Bottle Cooler",
  "Under-counter Fridge",
  "Cold Room",
  "Multideck Display Chiller",
  "Serve Over Counter",
  "Bar Fridge",
  "Prep Counter",
  "Saladette",
  "Chest Freezer",
  "Upright Freezer",
  "Glass Door Fridge",
  "Patisserie Counter",
  "Fish Counter",
  "Meat Counter",
  "Cellar Cooling System",
  "Slush Machine"
];

module.exports = refrigerationTypes;
