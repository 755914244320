import React, { useState, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams, Navigate } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import BookingForm from './components/BookingForm';
import ServiceTypeTemplate from './components/ServiceTypeTemplate';
import LoaderWrapper from './components/LoaderWrapper';
import ChatWidget from './components/ChatWidget/ChatWidget';
import locations from './data/locations';
import refrigerationTypes from './data/refrigerationTypes';
import { MAPBOX_API_KEY } from './mapboxConfig';

// Import ServicePageTemplate without lazy loading
import ServicePageTemplate from './components/ServicePageTemplate';

// Import PPM Portal components
import PPMLogin from './components/PPMPortal/Login';
import PPMAssets from './components/PPMPortal/Assets';
import PPMAdmin from './components/PPMPortal/Admin';

// Import Admin Panel components
import AdminLayout from './components/AdminPanel/AdminLayout';
import AdminLogin from './components/AdminPanel/AdminLogin';
import BookingsList from './components/AdminPanel/BookingsList';
import BookingDetail from './components/AdminPanel/BookingDetail';
import RoutingPanel from './components/AdminPanel/RoutingPanel';
import TaskBoard from './components/AdminPanel/TaskBoard';
import GoogleAdsManager from './components/AdminPanel/GoogleAdsManager';
import PPMManager from './components/AdminPanel/PPMManager';
import UserManagement from './components/AdminPanel/UserManagement';
import ChatPanel from './components/AdminPanel/ChatPanel/ChatPanel';

// Lazy load components
const ServiceAreasPage = lazy(() => import('./pages/ServiceAreasPage'));
const RegionOverview = lazy(() => import('./components/RegionOverview'));
const ServicesOverview = lazy(() => import('./pages/ServicesOverview'));

// Main site layout wrapper
function MainLayout({ children, openBookingForm }) {
  return (
    <div className="App flex flex-col min-h-screen">
      <Header openBookingForm={openBookingForm} />
      <main className="flex-grow">
        {children}
      </main>
      <Footer />
      <ChatWidget />
    </div>
  );
}

function ServicePageWrapper({ openBookingForm }) {
  const { location, region, town, service } = useParams();
  console.log('ServicePageWrapper params:', { location, region, town, service });

  const locationData = locations.find(loc => loc.name.toLowerCase().replace(/ /g, '-') === location.toLowerCase());
  console.log('LocationData:', locationData);

  let regionData, townData;
  if (locationData) {
    if (locationData.name === 'London') {
      regionData = locationData.regions.find(reg => reg.name.toLowerCase().replace(/ /g, '-') === region.toLowerCase());
    } else {
      regionData = locationData.regions[0];
    }
    console.log('RegionData:', regionData);

    if (regionData) {
      townData = regionData.towns.find(t => t.name.toLowerCase().replace(/ /g, '-') === town.toLowerCase());
    }
    console.log('TownData:', townData);
  }

  if (!locationData || !regionData || !townData) {
    console.log('Data not found');
    return <NotFound />;
  }

  return (
    <ServicePageTemplate
      location={locationData.name}
      region={regionData.name}
      town={townData.name}
      service={service}
      townData={townData}
      openBookingForm={openBookingForm}
    />
  );
}

function ServiceTypeWrapper({ service, openBookingForm }) {
  return (
    <ServiceTypeTemplate
      serviceType={service}
      title={`${service} Repair Services`}
      description={`We offer expert repair services for all types of ${service.toLowerCase()} units. Our skilled technicians ensure your ${service.toLowerCase()} systems are running efficiently and reliably.`}
      openBookingForm={openBookingForm}
    />
  );
}

function RegionOverviewWrapper() {
  const { location, region } = useParams();
  console.log('RegionOverviewWrapper - location:', location, 'region:', region);
  
  const locationData = locations.find(loc => loc.name.toLowerCase().replace(/ /g, '-') === location.toLowerCase());
  console.log('RegionOverviewWrapper - locationData:', locationData);
  
  let regionData;
  if (locationData) {
    if (locationData.name === 'London') {
      regionData = locationData.regions.find(reg => reg.name.toLowerCase().replace(/ /g, '-') === region.toLowerCase());
    } else {
      regionData = locationData.regions[0];
    }
  }
  console.log('RegionOverviewWrapper - regionData:', regionData);

  // If region is not provided, use the location data as the region data
  if (!region && locationData) {
    regionData = {
      name: locationData.name,
      towns: locationData.regions[0].towns
    };
  }

  return <RegionOverview region={regionData} location={locationData ? locationData.name : ''} />;
}

function App() {
  const [isBookingFormOpen, setIsBookingFormOpen] = useState(false);

  const openBookingForm = () => {
    console.log('openBookingForm called in App.js');
    setIsBookingFormOpen(true);
  };
  const closeBookingForm = () => setIsBookingFormOpen(false);

  return (
    <HelmetProvider>
      <Router>
        <LoaderWrapper>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              {/* Admin Panel routes - these don't use the main layout */}
              <Route path="/admin/login" element={<AdminLogin />} />
              <Route path="/admin" element={<AdminLayout />}>
                <Route index element={<Navigate to="/admin/bookings" replace />} />
                <Route path="bookings" element={<BookingsList />} />
                <Route path="bookings/:id" element={<BookingDetail />} />
                <Route path="routing" element={<RoutingPanel />} />
                <Route path="tasks" element={<TaskBoard />} />
                <Route path="ads" element={<GoogleAdsManager />} />
                <Route path="ppm" element={<PPMManager />} />
                <Route path="users" element={<UserManagement />} />
                <Route path="chat" element={<ChatPanel />} />
              </Route>

              {/* Main site routes - these use the main layout */}
              <Route path="*" element={
                <MainLayout openBookingForm={openBookingForm}>
                  <Routes>
                    <Route path="/" element={<Home openBookingForm={openBookingForm} mapboxApiKey={MAPBOX_API_KEY} />} />
                    <Route path="/service-areas" element={<ServiceAreasPage />} />
                    <Route path="/services" element={<ServicesOverview />} />
                    {refrigerationTypes.map((service, index) => (
                      <Route
                        key={index}
                        path={`/services/${service.toLowerCase().replace(/[^a-z0-9]+/g, '-')}`}
                        element={<ServiceTypeWrapper service={service} openBookingForm={openBookingForm} />}
                      />
                    ))}
                    <Route path="/service-areas/:location" element={<RegionOverviewWrapper />} />
                    <Route path="/service-areas/:location/:region" element={<RegionOverviewWrapper />} />
                    <Route path="/service-areas/:location/:town/:service" element={<ServicePageWrapper openBookingForm={openBookingForm} />} />
                    <Route path="/service-areas/:location/:region/:town/:service" element={<ServicePageWrapper openBookingForm={openBookingForm} />} />

                    {/* PPM Portal routes */}
                    <Route path="/ppm" element={<PPMLogin />} />
                    <Route path="/ppm/assets" element={<PPMAssets />} />
                    <Route path="/ppm/admin" element={<PPMAdmin />} />

                    {/* 404 route */}
                    <Route path="/404" element={<NotFound />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                  <BookingForm isOpen={isBookingFormOpen} onClose={closeBookingForm} />
                </MainLayout>
              } />
            </Routes>
          </Suspense>
        </LoaderWrapper>
      </Router>
    </HelmetProvider>
  );
}

export default App;
